<template>
  <div class="userFeedback">
    <div class="head">
      <h2>用户反馈列表</h2>
    </div>
    <div class="content">
      <a-table
        class="a-tabel"
        :columns="columns"
        :data-source="feedbackList"
        :loading="loading"
        :bordered="true"
        rowKey="id"
        :scroll="{
          x: true,
          y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 40px)',
        }"
      >
        <!-- 图片 -->
        <div slot="img" slot-scope="record" >
        <!-- <div slot="img" slot-scope="record"> -->
          <!-- <a-button v-if="record" size="small" @click="seeImg(record)"
            >查看图片</a-button
          >
          <a-button v-if="!record" size="small">无图</a-button> -->
          <span v-for="(item,index) in record.split(',')" :key="index">
               <img style="width:35px;margin:2px;border:1px solid #e3e3e3" :src="$apis.img_hand + item.slice(1)" alt="" class="handImg" @click="seeImg(record.split(','),index)">
               <!-- <img style="width:20px;margin:2px" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="" class="handImg" @click="seeImg('https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png')">
               <img style="width:20px;margin:2px" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="" class="handImg" @click="seeImg('https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png')"> -->
          </span>

         
        </div>
        <!-- 类型 -->
        <div slot="type" slot-scope="record">
          <a-tag color="green" v-if="record == 1">BUG</a-tag>
          <a-tag color="green" v-if="record == 2">内容</a-tag>
          <a-tag color="green" v-if="record == 3">功能</a-tag>
          <a-tag color="green" v-if="record == 4">设计</a-tag>
          <a-tag color="green" v-if="record == 5">建议</a-tag>
        </div>
        <!-- 反馈时间 -->
        <!-- <div slot="time" slot-scope="record">
          <span>{{ $newdate(record * 1000) }}</span>
        </div> -->
        <!-- 反馈状态 -->
        <div slot="handle_status" slot-scope="record">
          <a-tag v-if="record == 0" color="#ff4d4f">待处理</a-tag>
          <a-tag v-if="record == 1" color="#2db7f5">已处理</a-tag>
          <a-tag v-if="record == 2" color="orange">忽略</a-tag>
        </div>
        <!-- 处理时间 -->
        <div v-if="record" slot="handle_time" slot-scope="record">
          <span>{{ $newdate(record * 1000) }}</span>
        </div>

        <!-- 操作 -->
        <div slot="operation" slot-scope="record">
          <a-button
            size="small"
            v-if="record.handle_status == 0"
            @click="handleFeedback(record)"
            >处理</a-button
          >
          <a-button size="small" v-else disabled @click="handleFeedback(record)"
            >处理</a-button
          >
          <a-button size="small" style="margin:5px" @click="seeFeedbackdrawer(record)">查看</a-button>
        </div>
      </a-table>
    </div>

    <!-- 处理用户反馈 -->
    <div v-if="handlefeedbackvisible">
      <a-modal
        :visible="handlefeedbackvisible"
        title="处理用户反馈"
        @ok="handleOkfeed('reffeedForm')"
        @cancel="handleCancelfeed('reffeedForm')"
      >
        <a-form-model
          ref="reffeedForm"
          :model="feedForm"
          layout="horizontal"
          :hideRequiredMark="true"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }"
        >
          <a-form-model-item label="处理结果" has-feedback>
            <a-select
              :default-value="feedForm.status"
              v-model="feedForm.status"
              placeholder="请选择"
            >
              <a-select-option :value="1">
                已处理
              </a-select-option>
              <a-select-option :value="2">
                忽略
              </a-select-option>
            </a-select>
          </a-form-model-item>

        
        </a-form-model>
      </a-modal>
    </div>

    <!-- <div v-if="seeimgvisible">
        <SeeImg :seeimgvisible="seeimgvisible" :handle_img="hand_img" @handcanceimg="handcanceimg"></SeeImg>
    </div> -->

     <!-- 放大图片 -->
     <a-modal width="55vw" :visible="seeimgvisible" :footer="null" @cancel="handleCancelImg">
      <img alt="" style="width: 100%;padding:15px" :src="$apis.img_hand + previewImage" />
      <!-- <img alt="" style="width: 100%" :src="previewImage" /> -->
    </a-modal>

    <!-- 回复反馈 -->
    <div v-if="feedbackDrawer">
      <SeeFeedback :childrenDrawer="feedbackDrawer" :drawerrecord="drawerrecord" @handlecancel_="handlecancel_"></SeeFeedback>
    </div>
  </div>
</template>

<script>
import SeeImg from './seeimg.vue'
import SeeFeedback from './seeFeedback.vue'
const columns = [
  {
    title: "反馈ID",
    dataIndex: "id",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "id" },
  },
  {
    title: "反馈内容",
    dataIndex: "content",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "content" },
  },
  {
    title: "图片内容",
    dataIndex: "img",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "img" },
  },
  {
    title: "类型",
    dataIndex: "type",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "type" },
  },
  // {
  //   title: "联系方式",
  //   dataIndex: "contact",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "contact" },
  // },
  // {
  //   title: "反馈时间",
  //   dataIndex: "time",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "time" },
  // },
  {
    title: "学校ID",
    dataIndex: "school_id",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "school_id" },
  },
  {
    title: "学校名称",
    dataIndex: "school_name",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "school_name" },
  },

  // {
  //   title: "用户ID",
  //   dataIndex: "user_id",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "user_id" },
  // },
  // {
  //   title: "用户名称",
  //   dataIndex: "user_name",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "user_name" },
  // },
  // {
  //   title: "用户角色",
  //   dataIndex: "user_role",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "user_role" },
  // },
  {
    title: "反馈状态",
    dataIndex: "handle_status",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "handle_status" },
  },
  {
    title: "处理管理员ID",
    dataIndex: "handle_admin",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "handle_admin" },
  },
  {
    title: "处理时间",
    dataIndex: "handle_time",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "handle_time" },
  },
  {
    title: "操作",
    key: "operation",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "UserFeedback",
  components:{
    SeeImg,
    SeeFeedback
  },
  data() {
    return {
      loading: true, //加载
      columns, //表头
      feedbackList: [], //表数据
      handlefeedbackvisible: false,//处理模态框
      seeimgvisible: false,//查看放大图片模态框
      feedbackDrawer: false,//查看回复反馈内容的模态框
    //   seeimgstring:[],//查看图片模态框
      feedForm: {
        id: "",
        status: 1,
      },

      previewImage:'',//放大当前图片的地址
      drawerrecord:{},//查看当前反馈
    };
  },
  methods: {
    // 获取用户反馈列表数据
    getfeedback() {
      this.loading = true;
      this.$axios
        .post(this.$apis.queryFeedback)
        .then((res) => {
          if (res.data.code == 200) {
            this.feedbackList = res.data.data;
            this.loading = false;
            this.$message.success("查询成功");
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    //处理用户反馈
    handleFeedback(record) {
      this.handlefeedbackvisible = true;
      this.feedForm.id = record.id;
    },
    // 切换状态
    onchange(e) {
    
      this.feedForm.status = e;
    
    },
    // 提交处理结果
    handleOkfeed(formName) {
      let paraobj = {
        id: this.feedForm.id,
        status: this.feedForm.status,
      };
      this.$axios.post(this.$apis.handleFeedback, paraobj).then((res) => {
        if (res.data.code == 200) {
          this.getfeedback();
          this.handleCancelfeed("reffeedForm");
          this.$message.success("处理成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 取消处理的模态框
    handleCancelfeed(formName) {
      this.feedForm.status = 1;
      this.feedForm.id = "";
      this.$refs[formName].resetFields();
      this.handlefeedbackvisible = false;
    },
    // 查看图片
    seeImg(recordArr,i) {
        // this.hand_img=record.split(',')
        this.previewImage=recordArr[i].slice(1)
        this.seeimgvisible=true
    },
    // 关闭图片模态框
    handleCancelImg(){
        this.seeimgvisible=false
    },
    // 关闭回复反馈的抽屉
    handlecancel_(){
      this.feedbackDrawer=false
    },
    seeFeedbackdrawer(record){
      this.drawerrecord=record
    console.log(record,'record');
      this.feedbackDrawer=true
    }
  },
  created() {
    this.getfeedback();
  },
};
</script>

<style lang="less" scoped>
.userFeedback {
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 64px - 10px - 60px);
    overflow: auto;
  }
  .a-table {
    height: auto;
    width: auto;
  }

  /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        max-height: calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .handImg{
       cursor: pointer;
  transition: all 0.6s;
  }
  .handImg:hover{
    
     transform: scale(1.4);
  }
}
</style>
