<template>
  <div class="home">
    <a-layout class="a-layout">
      <!-- 顶部 -->
      <a-layout-header class="a-layout-header">
        <div class="logo">
          <div class="logo_title">数据采集智能协同与分析系统-后台管理</div>

          <a-tooltip placement="topLeft" title="侧边栏的开关">
            <div class="icon" @click="handleCollapsed">
              <a-icon
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                :style="{ fontSize: '18px', verticalAlign: 'middle' }"
              />
            </div>
          </a-tooltip>
        </div>
        <div class="user_img">
          <div class="dropdown">
            <a-dropdown class="my-self">
              <div class="my-self-flex">
                <a-avatar :size="24" icon="user" />
                <span class="my-self-name">
                  {{ userinfo && userinfo.name ? userinfo.name : '请登录' }}
                </span>
                <span v-if="userinfo">
                  <a-tag color="pink" v-if="userinfo.is_admin == 1">
                    管理员
                  </a-tag>
                  <a-tag color="green">
                    {{ userinfo.grade }}
                  </a-tag>
                </span>
              </div>
              <a-menu slot="overlay" @click="handleDropdownClick">
                <a-menu-item
                  key="editpassword"
                  :style="{ textAlign: 'center' }"
                >
                  <a-icon type="edit" />
                  修改密码
                </a-menu-item>
                <a-menu-item key="logout" :style="{ textAlign: 'center' }">
                  <a-icon type="import" />
                  退出登录
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-layout-header>
      <!-- 下部 -->
      <a-layout class="a-layout">
        <!-- 侧边 -->
        <a-layout-sider
          v-model="collapsed"
          :class="collapsed ? 'auto-collapsed' : ''"
          :style="{ overflowy: 'auto', background: '#f0f0f0' }"
        >
          <a-menu
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
            :openKeys="defaultSelectedKeys"
            @openChange="onOpenChange"
            v-model="defaultOpenkeys"
          >
            <template v-for="item in menuList">
              <a-sub-menu v-if="item.children" :key="item.key">
                <span slot="title">
                  <span>{{ item.title }}</span>
                </span>
                <a-menu-item
                  v-for="item_c in item.children"
                  :key="item_c.key"
                  @click="handlerouter(item_c.key)"
                >
                  {{ item_c.title }}
                </a-menu-item>
              </a-sub-menu>
              <a-menu-item
                v-else
                :key="item.key"
                @click="handlerouter(item.key)"
              >
                <a-icon type="control" />
                <span>{{ item.title }}</span>
              </a-menu-item>
            </template>
          </a-menu>
        </a-layout-sider>
        <!-- 内容 -->
        <a-layout-content>
          <div class="content_con">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- 修改密码模态框 -->
    <a-modal
      title="修改密码"
      :visible="editvisible"
      @ok="handleOk('refeditForm')"
      @cancel="handleCancel('refeditForm')"
    >
      <a-form-model
        ref="refeditForm"
        :model="editpwdForm"
        :rules="rulesEditForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="原密码" prop="pwd" has-feedback>
          <a-input-password v-model="editpwdForm.pwd" />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPwd" has-feedback>
          <a-input-password v-model="editpwdForm.newPwd" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="newAgain" has-feedback>
          <a-input-password v-model="editpwdForm.newAgain" />
        </a-form-model-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel('refeditForm')">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="submitLoadingAdd"
            @click="handleOk('refeditForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      userinfo: {},
      editvisible: false,
      submitLoadingAdd: false,
      editpwdForm: {
        pwd: '',
        newPwd: '',
        newAgain: '',
      },
      // 表单校验
      rulesEditForm: {
        pwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        newAgain: [
          { required: true, message: '请重新输入新密码', trigger: 'blur' },
        ],
      },
      collapsed: false, //侧边栏开关
      defaultSelectedKeys: [],
      defaultOpenkeys: [],
      menuList: [
        {
          title: '学校管理',
          key: 'School_p',
          children: [
            {
              title: '开通列表',
              key_p: 'School_p',
              key: 'School',
            },
            {
              title: '学校列表',
              key_p: 'School_p',
              key: 'School_list',
            },
            {
              title: '申请列表',
              key_p: 'School_p',
              key: 'Apply_list',
            },
          ],
        },
        {
          title: '权限菜单',
          key: 'AccessControl',
        },
        {
          title: '用户管理',
          key: 'UserManagement',
        },
        {
          title: '工单列表',
          key: 'WorkOrder',
        },
        {
          title: '操作日志',
          key: 'OperationLog',
        },
        {
          title: '用户反馈',
          key: 'UserFeedback',
        },
        // {
        //   title: '表单管理',
        //   key: 'Form_p',
        //   children: [
        //     {
        //       title: '创建表单',
        //       key_p: 'Form_p',
        //       key: 'CreateForm',
        //     },
        //     {
        //       title: '表单管理',
        //       key_p: 'Form_p',
        //       key: 'FormList',
        //     },
        //   ],
        // },
        {
          title: '知识服务',
          key: 'KnowledgeService',
        },
        {
          title: '第三方数据',
          key: 'ThirdParty_p',
          children: [
            {
              title: '创建第三方数据',
              key_p: 'ThirdParty_p',
              key: 'CreateThirdParty',
            },
            {
              title: '第三方数据列表',
              key_p: 'ThirdParty_p',
              key: 'ThirdPartyList',
            },
          ],
        },

        // {
        //   title: '关联列表',
        //   key: 'formula_p',
        //   children: [
        //     {
        //       title: '模板列表',
        //       key_p: 'formula_p',
        //       key: 'FormulaList',
        //     },
        //     {
        //       title: '填写公式',
        //       key_p: 'formula_p',
        //       key: 'Fillformula',
        //     },
        //     {
        //       title: '审核列表',
        //       key_p: 'formula_p',
        //       key: 'ShowList',
        //     },
        //   ],
        // },
      ],
    }
  },
  methods: {
    //   侧边栏的切换路由
    handlerouter(namerouter) {
      console.log(namerouter,'namerouter')

      this.$router.push({ name: namerouter })
    },
    //  触发下来单
    handleDropdownClick({ key }) {
      switch (key) {
        case 'logout':
          this.handleLogout()
          break

        case 'editpassword':
          this.handlleeditpwd()
          break

        default:
          break
      }
    },

    // 退出登录
    handleLogout() {
      console.log('out')
      this.$modal.confirm({
        title: '退出登录',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          try {
            sessionStorage.removeItem('userinfo')
            this.$router.replace('/user/login')
          } catch (error) {
            this.$router.replace('/user/login')
            window.location.reload()
          }
        },
      })
    },

    // 修改密码
    handlleeditpwd() {
      this.editvisible = true
      console.log('edit')
    },
    handleOk(formName) {
      this.submitLoadingAdd = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editpwdForm.newPwd !== this.editpwdForm.newAgain) {
            this.$message.error('新密码与确认密码不一致，请重新输入')
            return
          }

          let data = {
            pwd: this.editpwdForm.pwd,
            new_pwd: this.editpwdForm.newPwd,
          }
          this.$axios
            .post(this.$apis.resetPwd, data)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success(`成功 ${res.data.msg}`)
                this.handleCancel('refeditForm')
                // 重新登录
                setTimeout(() => {
                  sessionStorage.removeItem('userinfo')
                  this.$router.replace('/user/login')
                  window.location.reload()
                }, 1000)
              } else {
                this.$message.error(`修改失败 ${res.data.msg}`)
              }
              this.submitLoadingAdd = false
            })
            .catch((err) => {
              console.log(err)
              this.submitLoadingAdd = false
            })
        } else {
          this.submitLoadingAdd = false
          return false
        }
      })
    },
    // 关闭模态框
    handleCancel(formName) {
      this.editpwdForm = {
        pwd: '',
        newPwd: '',
        newAgain: '',
      }
      // 清空验证
      this.$refs[formName].resetFields()
      this.editvisible = false
    },
    // 侧导航开关
    // 切换侧栏收起状态
    handleCollapsed() {
      // this.$store.commit('SETCOLLAPSED', !this.collapsed)
      this.collapsed = !this.collapsed
    },
    onOpenChange(openKeys) {
      console.log(openKeys, 'openKeys')
      if (openKeys.length !== 0) {
        this.defaultSelectedKeys = openKeys
      } else {
        this.defaultSelectedKeys = ['']
      }
    },
  },

  created() {
    this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
    let { fullPath } = this.$route

    if (fullPath) {
      if (this.$route.meta.key_p) {
        this.defaultSelectedKeys = [this.$route.meta.key_p]
      }
      this.defaultOpenkeys = [this.$route.name]
      console.log(this.$route, ' this.$route', this.$router)
      console.log(this.defaultSelectedKeys, this.defaultOpenkeys)
      this.$router.push(fullPath)
    }
  },

  mounted() {
    // this.$nextTick(() => {
    // 	this.handlerouter('School')
    // })
  },
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  .a-layout-header {
    background: #fff;
    padding: 0;
    display: flex;
    box-shadow: 0 1px 4px 0 #ddd;
    justify-content: space-between;
    .logo {
      padding: 0 10px;
      display: flex;
      .logo_title {
        font-weight: 300px;
        font-size: 16px;
        color: #1890ff;
        margin-right: 20px;
      }
    }
    .user_img {
      padding: 0 10px;
      .dropdown {
        width: 200px;
        padding: 0 20px;
        cursor: pointer;
        .my-self-name {
          margin-left: 10px;
        }
        &:hover {
          background-color: #f8f8f9;
        }
      }
    }
  }
  .ant-layout-sider {
    width: 300px;
    height: calc(100vh - 64px);
  }
  .ant-layout-content {
    height: calc(100vh - 64px);
    overflow: auto;
    padding: 10px;

    .content_con {
      min-height: 280px;
      //   padding: 24px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 #ddd;
    }
  }
  .auto-collapsed {
    flex: 0 0 50px !important;
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;

    /deep/ .ant-menu-submenu-title {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    /deep/ .ant-menu-item {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    /deep/ .ant-menu-inline-collapsed {
      width: auto;
    }
  }
}
</style>
