<template>
  <div class="addschool">
    <a-modal
      width="600px"
      :visible="addshoolvisible"
      title="添加学校"
      @ok="handleOk('refaddForm')"
      @cancel="handleCancel('refaddForm')"
    >
      <a-form-model
        ref="refaddForm"
        :model="addForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="rulesAddForm"
      >
        <a-form-model-item label="学校机构代码" prop="code" has-feedback>
          <a-input style="width: 50%" v-model="addForm.code" />
          <a-button style="margin-left: 1%; width: 40%" @click="openSchoolList"
            >选择学校机构代码</a-button
          >
        </a-form-model-item>
        <a-form-model-item label="校名" prop="name" has-feedback>
          <a-input style="width: 91%" v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="学校服务器IP" prop="ip" has-feedback>
          <a-input style="width: 91%" v-model="addForm.ip" />
        </a-form-model-item>
        <a-form-model-item label="学校服务器端口" prop="port" has-feedback>
          <a-input style="width: 91%" v-model="addForm.port" />
        </a-form-model-item>

        <a-form-model-item label="状态" has-feedback>
          <!-- <a-input v-model="addForm.name" /> -->
          <a-switch v-model="addForm.status">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-model-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel('refaddForm')">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="handleOk('refaddForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
    <div v-if="schoolListvisible">
      <a-modal
        :visible="schoolListvisible"
        width="1000px"
        title="学校列表"
        @ok="handleOkList('reflistForm')"
        @cancel="handleCancelList('reflistForm')"
      >
        <a-form-model layout="horizontal" :hideRequiredMark="true">
          <!-- 搜索 -->
          <a-form-model-item has-feedback style="margin-bottom: 5px">
            <a-input
              style="width: 86%; margin-left: 30px; text-align: center"
              placeholder="请输入学校名称"
              @keyup.enter="search"
              v-model="school_name"
            >
              <a-icon slot="suffix" type="search" /> </a-input
            ><a-button style="margin-left: 10px" @click="search">搜索</a-button>
          </a-form-model-item>
          <a-form-model-item has-feedback>
            <div class="page_tabel">
              <a-table
                class="a_tabel"
                :row-selection="rowSelection"
                :columns="columns"
                :data-source="dataList"
                rowKey="organizationNo"
                :pagination="pagination"
                :loading="loading_tabel"
                :scroll="{
                  x: ' calc(600px)',
                  y: 'calc(100vh - 64px - 10px - 60px - 60px - 24px - 100px - 50px)',
                }"
                @change="handleTableChange"
              >
                <!-- 学校地址 -->
                <div slot="regionA" slot-scope="record">
                  {{ record.regionA }}
                  {{ record.regionB }}
                  {{ record.regionC }}
                  {{ record.regionD }}
                  {{ record.regionE }}
                </div>
              </a-table>
              <!-- <div class="pagination-container">
                <a-pagination
                  v-model="pagingnum"
                  :page-size="pagination.pagesize"
                  :total="pagination.total"
                  @change="pagtabel"
                />
              </div> -->
            </div>
          </a-form-model-item>
          <template slot="footer">
            <a-button key="back" @click="handleCancelList('reflistForm')">
              取消
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleOkList('reflistForm')"
            >
              确定
            </a-button>
          </template>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "学校机构代码",
    dataIndex: "organizationNo",
    key: "organizationNo",
    align: "center",
    width: 40,
    scopedSlots: { customRender: "organizationNo" },
  },
  {
    title: "学校名称",
    dataIndex: "organizationName",
    key: "organizationName",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "organizationName" },
  },
  {
    title: "学校地址",
    // dataIndex: "regionA",
    align: "center",
    width: 150,
    key: "regionA",
    scopedSlots: { customRender: "regionA" },
  },
];
export default {
  name: "Addschool",
  props: {
    addshoolvisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schoolListvisible: false, //学校机构列表
      loading: false, //加载
      loading_tabel: true, //加载
      addForm: {
        name: "",
        ip: "",
        status: true,
        code: "",
        port:'80'
      },
      rulesAddForm: {
        name: [{ required: true, message: "请输入校名", trigger: "blur" }],
        ip: [
          {
            required: true,
            message: "请输入学校服务器ip地址",
            trigger: "blur",
          },
        ],
        port: [
          {
            required: true,
            message: "请输入学校服务器端口",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入输入学校机构代码",
            trigger: "change",
          },
        ],
      },
      columns, //表头
      dataList: [], //表数据
      //  分页
      // pagination: {
      //   num: 1,
      //   pagesize: 0,
      //   total: 0,
      // },
      // 分页 h
      pagination: {
        current: 1, // 页码
        pageSize: 10, // 分页数
      },
      pagingnum: 1,
      selectedRows: [], //选中的数据
      school_name: "", //输入框的内容
    };
  },
  methods: {
    // 分页 h
    handleTableChange(pagination) {
      this.getGDSchoolList(pagination);
    },
    // 学校数据 h
    getGDSchoolList(pagination) {
      this.loading_tabel = true;
      const parger = { ...pagination };
      let paraobj = {
        pageSize: parger.pageSize,
        page: parger.current,
        name: this.school_name,
      };
      this.$axios.post(this.$apis.queryGDSchoolList, paraobj).then((res) => {
        if (res.data.code == 200) {
          const pagin = { ...this.pagination };
          pagin.total = res.data.data.total;
          pagin.pageSize = res.data.data.per_page;
          pagin.current = res.data.data.current_page;
          this.dataList = res.data.data.data;
          this.loading_tabel = false;
          this.pagination = pagin;
        }
      });
    },
    // 搜索 h
    search(e) {
      this.school_nam = e;
      this.getGDSchoolList();
    },
    // 打开学校机构代码 h
    openSchoolList() {
      this.getGDSchoolList();
      this.schoolListvisible = true;
    },
    //
    // getGDSchoolList(page, name) {
    //   this.loading_tabel=true
    //   let paraobj;
    //   if (name) {
    //     paraobj = {
    //       page,
    //       name,
    //     };
    //   } else {
    //     paraobj = {
    //       page,
    //     };
    //   }

    //   this.$axios
    //     .post(this.$apis.queryGDSchoolList, paraobj)
    //     .then((res) => {
    //       if (res.data.code == 200) {
    //         this.dataList = [];
    //         this.dataList = res.data.data.data;
    //         this.loading_tabel = false;
    //         this.pagination = {
    //           num: parseInt(res.data.data.current_page),
    //           total: parseInt(res.data.data.total),
    //           pagesize: res.data.data.per_page,
    //         };
    //       } else {
    //         this.$message.error(res.data.msg);
    //         this.loading_tabel = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // 确认
    handleOk(FormName) {
      this.$refs[FormName].validate((item) => {
        if (item) {
          this.loading = true;
          let paraobj = {
            name: this.addForm.name,
            ip: this.addForm.ip,
            status: this.addForm.status == true ? 1 : 0,
            code: this.addForm.code,
            port:this.addForm.port
          };

          this.$axios
            .post(this.$apis.addSchool, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success("添加成功");
                this.handlevisi(FormName, true);
                this.loading = false;
              } else {
                this.$message.error(res.data.msg);
                this.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName);
    },
    // 关闭模态框
    handlevisi(name, bool) {
      this.addForm = {
        name: "",
        ip: "",
        status: true,
        code: "",
        port:'80'
      };
      this.$refs[name].resetFields();
      this.$emit("handleaddvisible", bool);
    },

    // // 打开学校机构代码
    // openSchoolList() {
    //   this.getGDSchoolList(this.pagination.num);
    //   this.schoolListvisible = true;
    // },
    // 选择学校
    handleOkList() {
      if (this.selectedRows.length > 0) {
        if (this.selectedRows.length >= 2) {
          this.$message.warning("只能选择一条数据");
        } else {
          this.addForm.name = this.selectedRows[0].organizationName;
          this.addForm.code = this.selectedRows[0].organizationNo;

          this.handleCancelList();
        }
      } else {
        this.$message.warning("请选择学校");
      }
    },
    // 取消
    handleCancelList() {
      this.schoolListvisible = false;
      console.log(this.selectedRows, "关闭");
    },
    // 分页
    // pagtabel(i) {
    //   console.log("i==>", i);
    //   if (i == this.pagination.num) {
    //     return;
    //   }
    //   this.getGDSchoolList(i);
    // },
    // 点击搜索按钮
    // search() {
    //   if (this.school_name.length == 0) {
    //     return;
    //   }
    //   this.getGDSchoolList(1, this.school_name);
    // },
    //
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        // getCheckboxProps: (record) => ({
        //   props: {
        //     disabled: record.name === "Disabled User", // Column configuration not to be checked
        //     name: record.name,
        //   },
        // }),
      };
    },
  },
  watch: {
    school_name(i) {
      if (i.length == 0) {
        this.getGDSchoolList(1);
      }
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.page_tabel {
  .a_tabel {
    padding: 0;
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 45px;
  }
}
/deep/ .ant-modal-body {
  padding: 0;
}
/deep/ .ant-row {
  margin-bottom: 0;
}
</style>
