const domain = '/apis'
const domain_y = '/yapi' //vue.config.js中的代理

const domain_rpa = '/RQA-apis'
const api = {
	login: domain + '/api/login', // 登录
	Url_log: domain_y + '/vue_config.json',
	resetPwd: domain + '/api/Admin/resetPwd', // 修改密码
	// 学校列表
	SchoolList: domain + '/api/School/list', // 获取学校列表
	addSchool: domain + '/api/School/add', // 添加学校
	editSchool: domain + '/api/School/edit', // 编辑学校列表
	statusSchool: domain + '/api/School/status', // 编辑学校状态
	addMenuSchool: domain + '/api/School/addMenu', // 添加学校权限模块
	delMenuSchool: domain + '/api/School/delMenu', // 添加学校权限模块
	menuAuthSchool: domain + '/api/School/menuAuth', // 添加学校权限模块
	delSchool: domain + '/api/School/del', // 添加学校权限模块
	queryGDSchoolList: domain + '/api/School/queryGDSchoolList', // 查询学校机构代码
	queryApplyList: domain + '/api/School/apply_list', // 申请列表

	// 菜单权限
	MenuList: domain + '/api/Menu/list', // 查询权限菜单列表
	addMenu: domain + '/api/Menu/add', // 添加权限菜单列表
	delMenu: domain + '/api/Menu/del', // 删除权限菜单列表
	editMenu: domain + '/api/Menu/edit', // 编辑权限菜单列表
	statusMenu: domain + '/api/Menu/status', // 设置权限菜单状态
	menuOptionsList: domain + '/api/Menu/menuOptionsList', // 查询可选的菜单列表 (用于添加权限菜单)
	groupMenuList: domain + '/api/Menu/groupMenuList', // (根据模块分组)

	// 用户
	userList: domain + '/index.php/api/Admin/userList', // 查询隶属下级用户
	addUser: domain + '/index.php/api/Admin/addUser', // 添加下级用户

	// 操作日志
	queryLog: domain + '/api/Log/query', // 查询操作日志

	// 工单
	queryWorkorder: domain + '/api/Workorder/query', // 查询工单列表
	auditWorkorder: domain + '/api/Workorder/audit', // 审核工单列表
	revokeWorkorder: domain + '/api/Workorder/revoke', // 撤销工单列表
	delWorkorder: domain + '/api/Workorder/del', // 删除工单列表

	// 用户反馈
	queryFeedback: domain + '/api/Feedback/query', //查询用户反馈
	handleFeedback: domain + '/api/Feedback/handle', //处理用户反馈
	seeFeedbackComment: domain + '/api/Feedback/comment', //反馈回复记录
	replyFeedback: domain + '/api/Feedback/callback', //回复反馈
	img_hand: domain,
	EduSysRPADetail: domain + '/api/EduSysRPA/detail', // 获取模板详情（结构+数据）
	// 专项模板列表
	templateList: domain_y + '/api/Correlation/templateList', // 专项模板列表
	templateDetail: domain_y + '/api/Correlation/templateDetail', //  模板表详情
	operatorsList: domain_y + '/api/Correlation/operatorsList', // 公式函数符号列表
	checkEq: domain_y + '/api/Correlation/checkEq', // 公式校验
	saveEq: domain_y + '/api/Correlation/saveEq', // 保存公式
	aboutEq: domain_y + '/api/Correlation/aboutEq', // 相关公式
	editEq: domain_y + '/api/Correlation/editEq', // 编辑公式接口
	equationList: domain_y + '/api/Correlation/equationList', // 审核列表
	applyEq: domain_y + '/api/Correlation/applyEq', // 审核
	specialList: domain_y + '/api/Correlation/specialList',
	getOperaLogs: domain_y + '/api/Correlation/operaLogs', //操作记录
	getAssociate: domain_y + '/api/Correlation/associate', //公式字段联想接口
	getTemplatesByTableType: domain_y + '/api/Correlation/getTemplatesByTableType',

	// RPA
	shufu: domain_rpa + '/shufu', //测试
	getUploadTables: domain_rpa + '/shufu/getUploadTables', //查询表单列表接口
	getTranserseHeaderList: domain_rpa + '/shufu/getTranserseHeaderList', //获取横向表头列表数据接口
	updateHeaderList: domain_rpa + '/shufu/update', //编辑字段信息提交接口
	uploadFile: domain_rpa + '/shufu/upload', //文件上传接口
	getSpecialList: domain + '/api/Service/special', //专项查询

	// 服务

	getServiceList: domain + '/api/Service/query', //服务列表
	handleService: domain + '/api/Service/handle', //处理服务
	getOtherSpecialList: domain + '/api/Service/otherSpecialList', //第三方数据源列表
	getSchoolList: domain + '/api/Service/schoolList', // 获取学校列表- 无分页
	addOtherSpecial: domain + '/api/Service/otherSpecial', //添加第三方数据源
	getEachOtherSpecial: domain + '/api/Service/eachOtherSpecial', //读取第三方数据源
	saveUsedTables: domain + '/api/Service/usedTables', //保存选中的操作表


	// 发送短信
	sendText: domain + '/schoolapi/Service/query', // 通知学校联系人 - 发送短信

}
module.exports = api
