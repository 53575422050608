<template>
  <div class="seeFeedback">
    <a-drawer
      title=""
      placement="right"
      width="60vw"
      :closable="false"
      :visible="childrenDrawer"
      @close="onClose()"
      class="drawer"
    >
      <div>
        <!-- 问题本身 -->
        <div class="header">
          <!-- <div class="keytype">

          <a-tag style="margin:5px 10px 5px 0" color="green">{{
            feedtype[drawerrecord.type - 1]
          }}</a-tag>
          <a-tag style="margin:5px 10px" color="green">{{drawerrecord.user_id}}/{{
            drawerrecord.user_name
          }}</a-tag>
          <a-tag style="margin:5px 10px" color="green">{{
            drawerrecord.user_role
          }}</a-tag>
          <a-tag style="margin:5px 10px" color="green">
            {{ $newdate(drawerrecord.time * 1000)}}
          </a-tag>
        </div>
        <div class="content" style="color:#000;padding:10px 0;font-size:20px">
          {{ drawerrecord.content }}
        </div>
        <div class="content_img" v-if="drawerrecord.img">
          <span
            v-for="(item, index) in drawerrecord.img.split(',')"
            :key="index"
          >
            <img
              style="width:50px;height:50px;margin:2px;border:1px solid #e3e3e3"
              :src="$apis.img_hand + item.slice(1)"
              alt=""
              class="handImg"
            />
          </span>
        </div>
        <div class="btn_ch" style="line-height:50px">
          <a-button size="small" icon="edit" @click="goBtn">回复</a-button>
        </div> -->
          <div class="head_con">
            <div class="head_content">
              <div class="head_conent_img">
                <a-avatar slot="avatar" icon="user" />
              </div>

              <div class="head_content_left">
                <h4>
                  <a>{{ drawerrecord.user_name }}{{ drawerrecord.user_id }}</a>
                </h4>
                <div class="left_con">
                  <div class="type">
                    <div>
                      <span class="color">类型：</span
                      >{{ feedtype[drawerrecord.type - 1] }}
                    </div>
                    <div v-if="drawerrecord.user_role">
                      <span class="color">角色：</span
                      >{{ drawerrecord.user_role }}
                    </div>
                    <div v-if="drawerrecord.contact">
                      <span class="color">联系方式：</span
                      >{{ drawerrecord.contact }}
                    </div>
                    <div class="con_tent">
                      <div class="color">内容</div>
                      <div style="text-indent:2ex;">
                        {{ drawerrecord.content }}
                      </div>
                    </div>
                    <div class="con_tent" v-if="drawerrecord.img">
                      <div class="color">图片</div>
                      <div style="text-indent:2ex;">
                        <span
                        style="display: inline-block;"
                          v-for="(item, index) in drawerrecord.img.split(',')"
                          :key="index"
                          @click="cliImg(drawerrecord.img.split(','),index)"
                        >
                          <img
                            style="width:50px;height:50px;margin:2px;border:1px solid #e3e3e3"
                            :src="$apis.img_hand + item.slice(1)"
                            alt=""
                            class="handImg"
                          />
                        </span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

            <div class="head_deta">
              {{ $newdate(drawerrecord.time * 1000) }}
            </div>
          </div>
        </div>

        <!--答复列表 -->
        <a-list
          item-layout="horizontal"
          :data-source="feedbackcomlist"
          :pagination="pagination_props"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta v-if="item.type == 1">
              <a slot="title">{{ item.u_name }}</a>
              <a-avatar slot="avatar" icon="user" />
              <div slot="description">
                <div>
                  <!-- <div style="font-weight: bold;">内容:</div> -->
                  <div style="padding-left:2ex">{{ item.content }}</div>
                </div>
                <div v-if="item.img">
                  <!-- <div style="font-weight: bold;">图片:</div> -->
                  <div style="padding-left:2ex">
                    <span
                      style="display:inline-block"
                      v-for="(item_chil, index) in item.img.split(',')"
                      :key="index"
                       @click="cliImg(item.img.split(','),index)"
                    >
                      <img
                        style="width:50px;height:50px;margin:2px;border:1px solid #f8f8f8;cursor: pointer"
                        v-if="item_chil"
                        :src="$apis.img_hand + item_chil.slice(1)"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </a-list-item-meta>
            <a-list-item-meta v-if="item.type == 2">
              <a slot="title">{{ item.a_name }}</a>
              <a-avatar
                slot="avatar"
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              />
              <div slot="description">
                <div>
                  <!-- <div style="font-weight: bold;">内容:</div> -->
                  <div style="padding-left:2ex">{{ item.content }}</div>
                </div>
                <div v-if="item.img">
                  <!-- <div style="font-weight: bold;">图片:</div> -->
                  <div style="padding-left:2ex">
                    <span
                    style="display:inline-block"
                      v-for="(item_chil, index) in item.img.split(',')"
                      :key="index"
                       @click="cliImg(item.img.split(','),index)"
                    >
                      <img
                        style="width:50px;height:50px;margin:2px;border:1px solid #f8f8f8;cursor: pointer"
                        v-if="item_chil"
                        :src="'/scapi' + item_chil.slice(1)"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </a-list-item-meta>
            <div>
              {{ $newdate(item.create_time * 1000) }}
            </div>
          </a-list-item>
        </a-list>
        <!-- 回答框 -->
        <div class="imBox" style="margin-top:20px">
          <div class="imgBox_top" v-if="imgBox_arr.length < 6">
            <a-icon type="file-add" style="fontSize:20px" />
            <input
              type="file"
              class="imgBox_top_file"
              @change="changepic($event)"
              ref="file_"
            />
          </div>

          <!-- 输入框的内容 -->
          <!--  contenteditable="plaintext-only"只能输入纯文本 -->
          <div
            class="imgBox_content"
            :style="{ height: imgBox_arr.length > 0 ? '100px' : '150px' }"
            contenteditable="plaintext-only"
            ref="imgBox_content"
          ></div>
          <div
            v-if="imgBox_arr.length > 0"
            style="height:50px;line-height:50px;padding:0px 20px;position:absolute;left:0;bottom:10px"
          >
            <span
              v-for="(item, index) in imgBox_arr"
              :key="index"
              style="margin:0 5px"
            >
              <span
                style="display:inline-block;width: 50px;height: 50px;position: relative;"
              >
                <a-icon
                  type="close"
                  style="position: absolute;right: 0;top: 0;background-color:#fff"
                  @click="closeImg(index)"
                />
                <img :src="item" alt="" style="width: 100%;height: 100%;" />
              </span>
            </span>
          </div>
          <!-- 发送按钮 -->
          <div class="imgBox_btn" @click="sendOutcontent(drawerrecord.id)">
            发送
          </div>
        </div>
      </div>
    </a-drawer>

      <a-modal width="55vw" :visible="seeimgvisible" :footer="null" @cancel="handleCancelImg">
        
      <img alt="" style="width: 100%;padding:15px" :src="$apis.img_hand + previewImage" />
      <!-- <img alt="" style="width: 100%" :src="previewImage" /> -->
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "SeeFeedback",
  props: {
    childrenDrawer: {
      type: Boolean,
      default: false,
    },
    drawerrecord: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      feedbackcomlist: [],
      feedtype: ["BUG", "内容", "功能", "设计", "建议"],
      imgBox_arr: [], //发送的图片数组
      seeimgvisible:false,
      previewImage:''
    };
  },
  created() {
    this.seeFeedback(this.drawerrecord.id);
  },
  computed: {
    // 回复反馈列表的分页
    pagination_props() {
      return {
        showQuickJumper: true,
        pageSize: 10,
        total: this.feedbackcomlist.length,
        onchange(page, pagesize) {
          console.log(page, pagesize);
        },
      };
    },
  },
  methods: {
    //   获取回复反馈的内容
    seeFeedback(itemId, i) {
      let paraobj = {
        id: itemId,
      };
      this.$axios
        .post(this.$apis.seeFeedbackComment, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.feedbackcomlist = res.data.data.reverse();
            if (i == true) {
              this.goBtn();
            }
          }
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交回复
    sendOutcontent(itemId) {
      if (!this.$refs.imgBox_content.innerText && this.imgBox_arr.length == 0)
        return;

      let formData = new FormData();

      formData.append("id", itemId);
      formData.append("content", this.$refs.imgBox_content.innerText);
      if (this.imgBox_arr.length > 0) {
        for (let i = 0; i < this.imgBox_arr.length; i++) {
          let val = this.imgBox_arr[i].substring(
            this.imgBox_arr[i].indexOf("/") + 1,
            this.imgBox_arr[i].indexOf(";")
          );
          if (val == "jpeg") {
            val = "jpg";
          }
          let item = this.dataURLtoFile(this.imgBox_arr[i], `${i}.${val}`);
          formData.append("img[]", item);
        }
      }
      this.$axios
        .post(this.$apis.replyFeedback, formData)
        .then((res) => {
          if (res.data.code == 200) {
            this.imgBox_arr = [];
            this.$refs.imgBox_content.innerText = "";

            this.seeFeedback(itemId, true);
            this.$message.success("发送成功");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 关闭抽屉
    onClose() {
      this.$emit("handlecancel_");
    },
    // 上传图片
    changepic(e) {
      let than = this;
      let file = e.target.files[0];
      console.log(file, "file");
      let fal = this.verificationPicFile(file);
      if (fal == false) return;

      let reader = new FileReader(); //新建一个FileReader对象

      reader.readAsDataURL(file);
      reader.onload = function(e) {
        than.imgBox_arr.push(e.target.result);

        // console.log(e.target.result, 'e.target.result') //转换后的文件数据存储在e.target.result中
      };
      // this.dataurl.push(e.target.files[0])
    },
    // 判断文件的类型
    verificationPicFile(file) {
      var fileTypes = [".jpg", ".png"];
      var filePath = file.name;
      console.log(filePath, "filePath");
      //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
      if (filePath) {
        var isNext = false;
        var fileEnd = filePath.substring(filePath.indexOf("."));

        for (var i = 0; i < fileTypes.length; i++) {
          if (fileTypes[i] == fileEnd) {
            isNext = true;
            break;
          }
        }
        if (!isNext) {
          this.$message.warning("不支持该类型文件，仅支持jpg,png格式图片");
          file.value = "";
          return false;
        }
      } else {
        return false;
      }
    },
    // 将图片base64=>对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      //转换成file对象
      return new File([u8arr], filename, { type: mime });
      //转换成成blob对象
      //return new Blob([u8arr],{type:mime});
    },
    // 删除所截图的图片
    closeImg(index) {
      this.imgBox_arr.splice(index, 1);
    },
    // 回复按钮
    goBtn() {
      this.$nextTick(() => {
        //    let middle=this.$refs.middle;

        let middle = document.getElementsByClassName(
          "ant-drawer-wrapper-body"
        )[0];
        console.log(middle);
        middle.scrollTop = middle.scrollHeight;
      });
    },
    cliImg(imgArr,i){
     this.previewImage=imgArr[i].slice(1)
     this.seeimgvisible=true
      // this.$emit('seeImg_',imgArr,i)
    },
    handleCancelImg(){
      this.seeimgvisible=false
    }
  },
};
</script>

<style lang="less" scoped>
.seeFeedback {
  .drawer {
  }
}
.header {
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  .head_con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    .head_content {
      display: flex;
      flex: 1;
      align-items: flex-start;
      font-size: 0;
      .head_conent_img {
        margin-right: 16px;
      }
      .head_content_left {
        //  flex: 1 0;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 22px;
        h4 {
          margin-bottom: 4px;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          line-height: 22px;
          a {
            color: rgba(0, 0, 0, 0.65);
            transition: all 0.3s;
          }
        }
        .left_con {
          margin-left: 2ex;
          .color {
            color: rgb(45, 44, 44);
            // padding-right: 10px;
            display: inline-block;
          
            
          }
        }
      }
    }
    .head_deta {
    }
  }
}
.imBox {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // border: 1px solid #f6f6f6;
  height: 200px;
  width: 100%;
  // background: #f8f8f8;
  border: 1px solid #e1e1e1;
  z-index: 100;
  position: relative;
  .imgBox_top {
    padding: 5px 20px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    position: relative;
    .imgBox_top_file {
      z-index: 101;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 2.5px;
      left: 15px;
      opacity: 0;
      cursor: pointer;
    }
  }
  .imgBox_content {
    position: absolute;
    top: 30px;
    left: 0;
    padding: 10px 20px;
    height: 100px;
    width: 100%;
    outline: none; // 去除选中状态边框
    color: #000;
    overflow: auto;
  }
  .imgBox_btn {
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    right: 30px;
    bottom: 10px;
    background: #f5f5f5;
    border: 1px solid #e1e1e1;
    z-index: 101;
    cursor: pointer;
  }
  .imgBox_btn:hover {
    color: #fff;
    background-color: rgb(18, 150, 17);
  }
}
</style>
