import Vue from 'vue'
import App from './App.vue'
import router from './router/index'//路由
import store from './store'//vuex
import md5 from 'js-md5'//数据加密
import Apis from './api'//后端接口
import './plugins/ant'//ant ui框架按需导入
// import axios from 'axios'
import axios from './utils/request'

// 全局注册
Vue.config.productionTip = false
Vue.prototype.$md5 = md5
Vue.prototype.$apis = Apis
Vue.prototype.$axios = axios

// 全局方法 时间戳转为日期
let  Newdate=function(dateitem){
  let date=new Date(dateitem)
  let y=date.getFullYear();
  let m=date.getMonth()+1
  let d=date.getDate()
  let H=date.getHours()
  let M=date.getMinutes()
  let S=date.getSeconds()
  m= m<10? '0'+m : m
  d= d<10? '0'+d : d
  H= H<10? '0'+H : H
  M= M<10? '0'+M : M
  S= S<10? '0'+S : S

  return y +'-' + m + '-' + d +' ' + H + ':' +M + ':' + S
}

Vue.prototype.$newdate = Newdate



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
