<template>
	<div class="completeCreate">
		<a-result status="success" title="创建成功" sub-title="请等待RPA机器人执行任务">
			<template #extra>
				<a-button key="buy">
					再次创建
				</a-button>
				<a-button key="console" type="primary">
					返回列表
				</a-button>
			</template>
		</a-result>
		<div class="currentProgress">
			<h3 class="title">当前进度</h3>
			<a-steps :current="1">
				<a-step title="提交申请" description="20222/10/10" />
				<a-step title="RPA机器人处理" description="进行中" />
				<a-step title="正式上线" description="未开始" />
			</a-steps>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.currentProgress {
	width: 70%;
	margin: 0 auto;
	background: #f7f8fa;
	padding: 20px;
	.title {
		text-align: left;
	}
}
</style>
