<template>
 <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
    
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  data(){
    return {
      locale: zhCN
    }
  },
  created(){
 
  }
}
</script>
<style >
#app {
  width: 100%;
  height: 100%;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

/* ::-webkit-scrollbar {
   display: block;
  width: 8px;
  height: 6px;


} */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 14px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #d8d8d8;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: none;
}


</style>
