<template>
  <div class="editschoolstatus">
    <a-modal
      :visible="editshoolstatusvisible"
      :title="'修改' + ' ' + editForm.name + ' ' + '学校的状态'"
      @ok="handleOk('refeditForm')"
      @cancel="handleCancel('refeditForm')"
    >
      <a-form-model
        ref="refeditForm"
        :model="editForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="ruleseditForm"
      >
        <a-form-model-item label="状态" has-feedback>
          <a-select :default-value="editForm.status" @change="onchange">
            <a-select-option :value="0">
              停用
            </a-select-option>
            <a-select-option :value="1">
              启用
            </a-select-option>
            <a-select-option :value="2">
              设置限时
            </a-select-option>
            <a-select-option :value="3">
              删除程序
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          label="过期时间"
          has-feedback
          props="due_date"
          v-if="editForm.status == 2"
        >
          <!-- <a-input v-model="editForm.due_date"></a-input> -->
          <!-- :default-value="editForm.due_date ? moment(editForm.due_date, 'YYYYMMDD') : undefined" -->
          <a-date-picker
            style="width: 100%"
            show-time
            placeholder="请选择"
            @change="
              (date, dateString) => onChangeDate(date, dateString, 'due_date')
            "
          />

          <!-- <a-date-picker  :showToday="false"  v-model="editForm.due_date"  show-time placeholder="请选择" @change="Changedate" @ok="onOkdate" /> -->
        </a-form-model-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel('refeditForm')">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            @click="handleOk('refeditForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "Editschoolstatus",
  props: {
    editshoolstatusvisible: {
      type: Boolean,
      default: false,
    },
    editdata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editForm: {
        id: "",
        status: "",
        due_date: "",
        name: "",
        // status:true
      },
      ruleseditForm: {
        // status:[{ required: true, message: '请输入校名', trigger: 'blur' }],
        due_date: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleOk(FormName) {
      this.$refs[FormName].validate((item) => {
        if (item) {
          let paraobj = {};
          if (this.editForm.status == 2) {
            paraobj = {
              id: this.editForm.id,
              status: this.editForm.status,
              due_date: this.editForm.due_date,
            };
          } else {
            paraobj = {
              id: this.editForm.id,
              status: this.editForm.status,
            };
          }

          this.$axios
            .post(this.$apis.statusSchool, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                if(res.data.msg =='success'){
                     this.$message.success("编辑状态成功");
                }else{
                   this.$message.success(res.data.msg);
                }
               
                this.handlevisi(FormName, true);
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName);
    },
    // 关闭模态框
    handlevisi(name, bool) {
      this.editForm = {
        id: "",
        status: "",
        due_date: "",
      };
      this.$refs[name].resetFields();
      this.$emit("handleeditvisible", bool);
    },
    // 切换状态
    onchange(e) {
      this.editForm.status = e;
    },
    // 日期时间切换
    // Changedate(e){
    //   console.log(e,'e');
    // },
    // onOkdate(i){
    //   console.log(i,'i');
    // },
    // 选择日期方法
    onChangeDate(date, dateString, attr) {
      console.log(dateString, "dateString", attr);
      this.editForm[attr] = dateString;
    },
  },
  created() {
    console.log(this.editdata, "this.editdata");
    // for (let key in this.editForm) {
    //   this.editForm[key] = this.editdata[key];
    // }
    this.editForm.status = this.editdata.status;
    this.editForm.id = this.editdata.id;
    this.editForm.name = this.editdata.name;
    if (this.editdata.status == 2) {
      this.editForm.due_date = this.editdata.due_date;
    }
  },
};
</script>

<style></style>
