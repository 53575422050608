<template>
  <div class="sendProgress">
    <a-modal
      :visible="sendProgressvisble"
      :footer="null"
      title="审核进度"
      @ok="handleOkpro()"
      @cancel="handleCancelpro()"
    >
      <div class="steps">
        <a-steps direction="vertical" :current="current">
          <a-step title="工单创建"  />
          <a-step
            v-if="sendRecord.create_admin_grade >= 5"
            :title="sendRecord.audit_5_name + '(五级管理员)'"
            :description="
              sendRecord.audit_5 == 0
                ? '待审核'
                : sendRecord.audit_5 == 1
                ? '通过'
                : '不通过'
            "
          />
          <a-step
            v-if="sendRecord.create_admin_grade >= 4"
            :title="sendRecord.audit_4_name + '(四级管理员)'"
            :description="
              sendRecord.audit_4 == 0
                ? '待审核'
                : sendRecord.audit_4 == 1
                ? '通过'
                : '不通过'
            "
          />
          <a-step
            v-if="sendRecord.create_admin_grade >= 3"
            :title="sendRecord.audit_3_name + '(三级管理员)'"
            :description="
              sendRecord.audit_3 == 0
                ? '待审核'
                : sendRecord.audit_3 == 1
                ? '通过'
                : '不通过'
            "
          />
          <a-step
            v-if="sendRecord.create_admin_grade >= 2"
            :title="sendRecord.audit_2_name + '(二级管理员)'"
            :description="
              sendRecord.audit_2 == 0
                ? '待审核'
                : sendRecord.audit_2 == 1
                ? '通过'
                : '不通过'
            "
          />
          <a-step
            v-if="sendRecord.create_admin_grade >= 1"
            :title="sendRecord.audit_1_name + '(一级管理员)'"
            :description="
              sendRecord.audit_1 == 0
                ? '待审核'
                : sendRecord.audit_1 == 1
                ? '通过'
                : '不通过'
            "
          />
        </a-steps>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    sendProgressvisble: {
      type: Boolean,
      default: false,
    },
    sendRecord: {
      type: Object,
      default: () => {},
    },
  },
  name: "SendProgress",
  data() {
    return {
      current: 1,
    };
  },
  methods: {
    // 确定
    handleOkpro() {},
    // 关闭
    handleCancelpro() {
      this.$emit("handvisble");
    },
  },
  created() {
    switch (this.sendRecord.create_admin_grade) {
      case 1:
        if (this.sendRecord.audit_1 > 0) {
          this.current = this.current + 1;
        } else {
          this.current = this.current;
        }
        break;

      case 2:
        if (this.sendRecord.audit_1 > 0) {
          this.current = this.current + 2;
        } else {
          if (this.sendRecord.audit_2 > 0) {
            this.current = this.current + 1;
          } else {
            this.current = this.current;
          }
        }
        break;
      case 3:
        if (this.sendRecord.audit_1 > 0) {
          this.current = this.current + 3;
        } else {
          if (this.sendRecord.audit_2 > 0) {
            this.current = this.current + 2;
          } else {
            if (this.sendRecord.audit_3 > 0) {
              this.current = this.current + 1;
            } else {
              this.current = this.current;
            }
          }
        }
        break;
      case 4:
        if (this.sendRecord.audit_1 > 0) {
          this.current = this.current + 4;
        } else {
          if (this.sendRecord.audit_2 > 0) {
            this.current = this.current + 3;
          } else {
            if (this.sendRecord.audit_3 > 0) {
              this.current = this.current + 2;
            } else {
              if (this.sendRecord.audit_4 > 0) {
                this.current = this.current + 1;
              } else {
                this.current = this.current;
              }
            }
          }
        }
        break;
      case 5:
        if (this.sendRecord.audit_1 > 0) {
          this.current = this.current + 5;
        } else {
          if (this.sendRecord.audit_2 > 0) {
            this.current = this.current + 4;
          } else {
            if (this.sendRecord.audit_3 > 0) {
              this.current = this.current + 3;
            } else {
              if (this.sendRecord.audit_4 > 0) {
                this.current = this.current + 2;
              } else {
                if (this.sendRecord.audit_5 > 0) {
                  this.current = this.current + 1;
                } else {
                  this.current = this.current;
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }
  },
  mounted() {},
};
</script>

<style></style>
