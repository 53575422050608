<template>
  <div class="login">
    <div class="login_form">
      <h1>数据采集智能协同与分析系统-后台管理</h1>
      <a-form-model
        id="components-form-demo-normal-login"
         ref="refForm"
        :model="form"
        :rules="rulesForm"
        :hideRequiredMark="true"
        @submit="handleSubmit('refForm')"
        @submit.native.prevent
        class="login-form"
       
      >
        <a-form-model-item  prop="phone">
          <a-input placeholder="账号" v-model="form.phone	" >
            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item   prop="password">
          <a-input
            type="password"
            placeholder="密码"
            v-model="form.password"
          >
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" class="login-form-button" :loading="loading">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  name: "Login",
  data() {
    return {
      loading: false,
      form: {
        phone	: "",
        password: "",
      },
      rulesForm: {
        phone: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleSubmit(formName) {

      this.$refs[formName].validate(valid => {
        if (valid) {
          // loading
          this.loading = true

          let paramsObj = {
            phone: this.form.phone,
            password:this.form.password
            // password: this.$md5(this.form.password)
          }
          console.log('paramsObj==>', paramsObj,'this.$apis.login',this.$apis.login)
          this.$axios
            .get(this.$apis.login, {
              params: paramsObj
            })
            .then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.$notification['success']({
                  message: '登录成功'
                })
                sessionStorage.setItem('userinfo', JSON.stringify(res.data.data))
                this.loading = false
                this.$router.replace('/')
              } else {
                this.$notification['error']({
                  message: res.data.msg
                })
                this.loading = false
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  },

  created() {},
};
</script>

<style lang="less" scoped>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  .login_form {
    padding: 100px 0;
  }
}
</style>
