<template>
  <div class="createForm">
    <div class="head">
      <h2>创建模板表单</h2>
    </div>
    <div class="content">
      <div class="headerList">
        <a-steps
          v-model="current"
          type="navigation"
          size="small"
          :style="stepStyle"
        >
          <a-step
            title="填写专项信息"
            description="上传EXCEL模板"
            :disabled="true"
          />
          <a-step
            title="匹配字段"
            description="调整字段的类型与属性"
            :disabled="true"
          />
          <a-step
            title="完成创建"
            status="wait"
            description="创建成功"
            :disabled="true"
          />
        </a-steps>
      </div>
      <div class="box">
        <div v-if="current == 0">
          <FillInfo @sumbitCurrent="sumbitCurrent"></FillInfo>
        </div>
        <div v-if="current == 1">
          <MatchField @sumbitCurrent="sumbitCurrent"></MatchField>
        </div>
        <div v-if="current == 2">
          <CompleteCreate></CompleteCreate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FillInfo from './componetns/fillInfo.vue'
import MatchField from './componetns/matchField.vue'
import CompleteCreate from './componetns/completeCreate.vue'
export default {
  name: 'CreateForm',
  components: {
    FillInfo,
    MatchField,
    CompleteCreate,
  },
  data() {
    return {
      current: 0,
      stepStyle: {
        marginBottom: '60px',
        boxShadow: '0px -1px 0 0 #e8e8e8 inset',
      },
    }
  },
  methods: {
    // 下一步，匹配字段
    sumbitCurrent(i) {
      console.log(i, '')
      this.current = i
    },
  },
}
</script>

<style lang="less" scoped>
.createForm {
  height: calc(100vh - 80px);
  overflow: scroll;
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    .headerList {
      width: 80%;
      margin: 0 auto;
    }
    .box {
      text-align: center;
    }
  }
}
/deep/.ant-steps {
  margin-bottom: 10px !important;
}
</style>
