<template>
  <div class="addaccess">
    <a-modal
      :visible="editshoolvisible"
      title="编辑权限菜单"
      @ok="handleOk('refeditForm')"
      @cancel="handleCancel('refeditForm')"
    >
      <a-form-model
        ref="refeditForm"
        :model="editForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="rulesEditForm"
      >
        <!-- <a-form-model-item label="名称" prop="name" has-feedback>
          <a-input v-model="editForm.name" />
        </a-form-model-item>
        <a-form-model-item label="模块" prop="model" has-feedback>
          <a-input v-model="editForm.model" />
        </a-form-model-item> -->
        <a-form-model-item label="模块" prop="model" has-feedback>
          <a-select v-model="editForm.model">
            <a-select-option
              v-for="item in menuList"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name" has-feedback>
          <a-select v-model="editForm.name">
            <a-select-option
              v-for="item in menuListSon"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <template slot="footer">
          <a-button key="back" @click="handleCancel('refeditForm')">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="handleOk('refeditForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Editaccess',
  props: {
    editshoolvisible: {
      type: Boolean,
      default: false,
    },
    editdata: {
      type: Object,
      default: () => {},
    },
    menuList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      editForm: {
        id: '',
        name: '',

        model: '',
        // status:''
      },
      rulesEditForm: {
        name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
        model: [{ required: true, message: '请输入', trigger: 'blur' }],
      },

      menuListSon: [],
      num: 0,
    }
  },
  methods: {
    // 确定
    handleOk(FormName) {
      this.$refs[FormName].validate((item) => {
        if (item) {
          this.loading = true
          let paraobj = {
            id: this.editForm.id,
            name: this.editForm.name,
            model: this.editForm.model,
            // status:this.editForm.status
          }

          this.$axios
            .post(this.$apis.editMenu, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success('修改成功')
                this.handlevisi(FormName, true)
                this.loading = false
              } else {
                this.$message.error(res.data.msg)
                this.loading = false
              }
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName)
    },

    // 关闭模态框
    handlevisi(name, bool) {
      this.editForm = {
        id: '',
        name: '',
        model: '',
        // status:''
      }
      this.$refs[name].resetFields()
      this.$emit('handleeditvisible', bool)
    },

    onchangeModel(e) {
      console.log(e, 'ee')
      this.num++
      this.menuList.forEach((im) => {
        console.log()
        if (e == im.name) {
          console.log(1111)
          this.menuListSon = im.son
          console.log(this.menuListSon, ' this.menuListSon ')
        }
      })
    },
  },
  watch: {
    'editForm.model'(name) {
      if (this.num !== 0) {
        this.editForm.name = ''
      }
      this.onchangeModel(name)
      console.log(name, 'name')
    },
  },
  created() {
    for (let key in this.editdata) {
      this.editForm[key] = this.editdata[key]
    }
  },
  mounted() {},
}
</script>

<style>
</style>