<template>
  <div class="addaccess">
    <a-modal
      :visible="addshoolvisible"
      title="添加权限菜单"
      @ok="handleOk('refaddForm')"
      @cancel="handleCancel('refaddForm')"
    >
      <a-form-model
        ref="refaddForm"
        :model="addForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="rulesAddForm"
      >
        <a-form-model-item label="模块" prop="model" has-feedback>
          <a-select v-model="addForm.model">
            <a-select-option
              v-for="item in menuList"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name" has-feedback>
          <a-select v-model="addForm.name">
            <a-select-option
              v-for="item in menuListSon"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="状态" has-feedback>
          <!-- <a-input v-model="addForm.name" /> -->
          <a-select default-value="1" @change="onchange">
            <a-select-option value="0"> 停用 </a-select-option>
            <a-select-option value="1"> 启用 </a-select-option>
            <a-select-option value="2"> 维护 </a-select-option>
          </a-select>
        </a-form-model-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel('refaddForm')">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="handleOk('refaddForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Addaccess',
  props: {
    addshoolvisible: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      addForm: {
        model: '',
        name: '',
        status: 1,
      },

      rulesAddForm: {
        name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
        model: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      // menuList: [],
      menuListSon: [],
    }
  },
  methods: {
    // 确定
    handleOk(FormName) {
      console.log(this.addForm, 'this.addForm')
      this.$refs[FormName].validate((item) => {
        if (item) {
          this.loading = true
          let paraobj = {
            name: this.addForm.name,
            model: this.addForm.model,
            status: this.addForm.status,
          }

          this.$axios
            .post(this.$apis.addMenu, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success('添加成功')
                this.handlevisi(FormName, true)
                this.loading = false
              } else {
                this.$message.error(res.data.msg)
                this.loading = false
              }
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName)
    },

    // 关闭模态框
    handlevisi(name, bool) {
      this.addForm = {
        name: '',
        model: '',
        status: 1,
      }
      this.$refs[name].resetFields()
      this.$emit('handleaddvisible', bool)
    },
    // 下拉框的切换
    onchange(e) {
      this.addForm.status = e
    },

    onchangeModel(e) {
      this.menuList.forEach((im) => {
        if (e == im.name) {
          this.menuListSon = im.son
        }
      })
    },
  },
  watch: {
    'addForm.model'(name) {
      this.addForm.name = ''
      this.onchangeModel(name)
    },
  },
  created() {},
}
</script>

<style>
</style>