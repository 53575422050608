<template>
  <div class="seeImg">
   <a-drawer
      title="反馈图片内容"
      
      placement="right"
      :closable="true"
      :visible="seeimgvisible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
     <div class="img_til" >
       <img  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" >
       <div class="Mantle">
          <a-icon type="eye" class="icon"  @click="handleImg(111)" />
         </div>
      
     </div>
     <!-- <div class="img_til" >
       <img  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="">
     </div> -->
     <div class="img_til" v-for="(item,index) in  handle_img" :key="index">
         <img  :src="$apis.img_hand + item" alt="">
           <div class="Mantle">
          <a-icon type="eye" class="icon"  @click="handleImg(item)" />
         </div>
     </div>
    </a-drawer>

    <!-- 放大图片 -->
     <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="$apis.img_hand + previewImage" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props:{
    seeimgvisible:{
      type:Boolean,
      default:false
    },
    handle_img:{
      type:Array,
      default:()=>{}
    }
  },
name:'SeeImg',
data(){
  return {
    previewVisible:false,//放大图片的模态框
    previewImage:'',//图片地址
  }
},
methods:{
   afterVisibleChange(val) {
      console.log('visible', val);
    },
  onClose(){
    this.$emit('handcanceimg')
  },
  // 放大图片
  handleImg(item){
    this.previewVisible=true
    this.previewImage=item
    console.log(item);
  },
  // 关闭放大图片的模态框
  handleCancel(){
    this.previewVisible=false
    this.previewImage=''
  },

},
created(){
console.log(this.handle_img,'handle_img');
}
}
</script>

<style lang="less" scoped>

 .img_til{
   width:100%;
   margin:10px 0;
   position: relative;
   border:1px solid #f3f3f3;
 img{
      width: 100%;
    }
    .Mantle{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
          display: none;
            color: #fff;
            font-size: 24px;
        }
    }
    .Mantle:hover{
      background: rgba(0,0,0,.7);
      .icon{
        display: block;
      }
     
    }
  
  }

</style>