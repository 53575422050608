<template>
  <div class="operationLog">
    <div class="head">
      <h2>操作日志列表</h2>
    </div>
    <div class="content">
      <a-table
        class="a-tabel"
        :columns="columns"
        :data-source="logList"
        :loading="loading"
        :bordered="true"
        childrenColumnName="subordinate"
        rowKey="id"
        :scroll="{
          x: true,
          y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 50px)',
        }"
      >
        <div slot="create_time" slot-scope="record">
          {{ $newdate(record * 1000) }}
        </div>

        <!-- 历史数据 -->
        <!-- <div slot="old_content" slot-scope="record">
          <div v-if="record.old_content">
            <ul v-if="Array.isArray(record.old_content)" class="new_ul">
              <li v-for="(item,index) in record.old_content" :key="index" > {{(item)}}</li>
            </ul>
            <div v-else>
              <div v-if="typeof(record.old_content) =='object'">
                 <ul  class="new_ul">
                  <li v-for="(item,index) in Object.entries(record.old_content) " :key="index" > {{item}}</li>
                </ul>
              </div>
              <span v-else> {{record.old_content}}</span>
              </div>
           
          </div>
         
        </div> -->

        <div slot="old_content" slot-scope="record">
          <div v-if="record.name == '添加管理员'">
            <div>{{ record.old_content }}</div>
          </div>
          <div v-if="record.name == '添加权限菜单'">
            <div>{{ record.old_content }}</div>
          </div>
          <div v-if="record.name == '删除权限菜单'">
            <div>{{ record.old_content }}</div>
          </div>

          <div v-if="record.name == '编辑权限菜单'">
            <div>{{ record.old_content }}</div>
          </div>
          <!-- 学校 -->
          <div v-if="record.name == '添加学校'">
            {{ record.old_content }}
          </div>
          <div v-if="record.name == '添加学校权限'">
            {{ record.old_content }}
          </div>
          <div v-if="record.name == '提交删除学校工单'">
            {{ record.old_content }}
          </div>
          <div v-if="record.name == '提交删除学校源码工单'">
            {{ record.old_content }}
          </div>
          <div v-if="record.name == '编辑学校资料'">
            <div v-if="record.old_content">
              <div>
                <div
                  v-if="
                    record.old_content[record.old_content.length - 1]
                      .update_time == ''
                  "
                >
                  <div>
                    <div v-if="record.old_content[0].name">    <h3 style="  display: inline-block;">学校名称: </h3> {{record.old_content[0].name}}</div>
                    <div v-if=" record.old_content[0].ip">  <h3 style="  display: inline-block;">学校服务器IP: </h3> {{ record.old_content[0].ip}}</div>
                    <div v-if="record.old_content[0].code"> <h3 style="  display: inline-block;">学校机构代码: </h3> {{record.old_content[0].code}}</div>
                    <!-- {{
                      record.old_content[0].name ||
                        record.old_content[0].ip ||
                        record.old_content[0].code
                    }} -->
                  </div>
                </div>
              </div>
              <div
                v-if="
                  record.old_content[record.old_content.length - 1]
                    .update_time !== ''
                "
              >
                <div v-for="(item, index) in record.old_content" :key="index">
                  <div v-if="item.name">   <h3 style="  display: inline-block;">学校名称: </h3>{{ item.name }}</div>
                  <div v-if="item.code"><h3 style="  display: inline-block;">学校服务器IP: </h3>{{  item.code }}</div>
                  <div v-if="item.ip">  <h3 style="  display: inline-block;">学校机构代码: </h3> {{ item.ip }}</div>
                  <!-- <div v-if="item.status">{{item.status ==1 ? '启用' :'停用'}}</div> -->
                </div>
              </div>
              <!-- 审核工单 -->
              <div
                v-if="
                  Object.keys(
                    record.new_content[record.new_content.length - 1]
                  )[0] == 'status'
                "
              >
                <!-- <div v-if="record.old_content[record.old_content.length - 1].status"> -->

                <div>
                  {{
                    record.old_content[0][
                      Object.keys(record.old_content[0])[0]
                    ] == 0
                      ? "待审核"
                      : ""
                  }}
                </div>
              </div>
              <!-- 删除学校权限状态 -->
              <!-- 切换学校权限状态 -->
              <!-- 编辑学校基本情况 -->
              <!-- <div v-if=""></div> -->
            </div>
            <div v-else>{{ record.old_content }}</div>
          </div>

          <div v-if="record.name == '添加编辑学校资料'">
            <ul>
              <!-- <li>{{record.old_content.id}}</li> -->
              <li><h3 style="  display: inline-block;">学校名称：</h3> {{ record.old_content.name }}</li>
              <li><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ record.old_content.ip }}</li>
              <li><h3 style="  display: inline-block;">状态：</h3>{{ record.old_content.status == 1 ? "启用" : "停用" }}</li>
            </ul>
          </div>

          <div v-if="record.name == '修改学校状态'">
            <div v-if="Array.isArray(record.old_content)">
              <a-tag color="#ff4d4f" v-if="record.old_content[0].status == 0"
                >停用</a-tag
              >
              <a-tag color="#2db7f5" v-if="record.old_content[0].status == 1"
                >启用</a-tag
              >
              <a-tag color="orange" v-if="record.old_content[0].status == 2"
                >设置限时</a-tag
              >
              <a-tag color="red" v-if="record.old_content[0].status == 3"
                >删除程序</a-tag
              >
            </div>
            <div v-else>
              <ul>
                <!-- <li>{{ record.oldcontent.id }}</li> -->
                <li><h3 style="  display: inline-block;">学校名称：</h3>{{ record.old_content.name }}</li>
                <li><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ record.old_content.ip }}</li>
                <li v-if="record.old_content.status == 0">停用</li>
                <li v-if="record.old_content.status == 1">启用</li>
                <li v-if="record.old_content.status == 2">设置限时</li>
                <li v-if="record.old_content.status == 3">删除程序</li>
              </ul>
            </div>
          </div>

          <div v-if="record.name == '设置学校状态'">
            <a-tag color="#ff4d4f" v-if="record.old_content[0].status == 0"
              >停用</a-tag
            >
            <a-tag color="#2db7f5" v-if="record.old_content[0].status == 1"
              >启用</a-tag
            >
            <a-tag color="orange" v-if="record.old_content[0].status == 2"
              >设置限时</a-tag
            >
            <a-tag color="red" v-if="record.old_content[0].status == 3"
              >删除程序</a-tag
            >
          </div>
        </div>
        <!-- 操作后的数据 -->
        <!-- <div slot="new_content" slot-scope="record">
          <div v-if="record.new_content">
            <ul v-if="Array.isArray(record.new_content)" class="new_ul">
              <li v-for="(item,index) in record.new_content" :key="index" > {{(item)}}</li>
            </ul>
            <div v-else>
              <div v-if="typeof(record.new_content) =='object'">
                 <ul  class="new_ul">
                  <li v-for="(item,index) in Object.entries(record.new_content) " :key="index" > {{item}}</li>
                </ul>
              </div>
              <span v-else> {{record.new_content}}</span>
              </div>
           
          </div>
         
        </div> -->
        <div slot="new_content" slot-scope="record">
          <!-- 用户 -->
          <div v-if="record.name == '添加管理员'">
            <div>{{ record.new_content }}</div>
          </div>
          <!-- 权限菜单 -->
          <div v-if="record.name == '添加权限菜单'">
            <div>{{ record.new_content }}</div>
          </div>
          <div v-if="record.name == '删除权限菜单'">
            <div>{{ record.new_content }}</div>
          </div>
          <div v-if="record.name == '编辑权限菜单'">
            <div><h3 style="  display: inline-block;">权限名称：</h3>{{ record.new_content.name }}</div>
            <div><h3 style="  display: inline-block;">权限模块名称：</h3>{{ record.new_content.model }}</div>
          </div>
          <!-- 学校 -->
          <div v-if="record.name == '添加学校'">
            <ul>
              <!-- <li>{{record.new_content.id}}</li> -->
              <li><h3 style="  display: inline-block;">学校名称：</h3>{{ record.new_content.name }}</li>
              <li><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ record.new_content.ip }}</li>
              <li><h3 style="  display: inline-block;">学校机构代码：</h3>{{ record.new_content.code }}</li>
              <li v-if="record.new_content.status == 1"><h3 style="  display: inline-block;">当前状态：</h3>启用</li>
              <li v-if="record.new_content.status == 0"><h3 style="  display: inline-block;">当前状态：</h3>停用</li>
            </ul>
          </div>
          <div v-if="record.name == '添加学校权限'">
           <h3 style="  display: inline-block;" v-if="record.new_content.auth ==0">权限： <a-tag  color="#ff4d4f" >{{mentName(record.new_content.mid).length >0 ? mentName(record.new_content.mid)[0].name :  record.new_content.mid }}</a-tag></h3>
           <h3 style="  display: inline-block;"  v-if="record.new_content.auth ==1" >权限： <a-tag color="#2db7f5" >{{mentName(record.new_content.mid).length >0 ? mentName(record.new_content.mid)[0].name :  record.new_content.mid }}</a-tag></h3>
            
          </div>
          <div v-if="record.name == '提交删除学校工单'">
            {{ record.new_content }}
          </div>
          <div v-if="record.name == '提交删除学校源码工单'">
            {{ record.new_content == 3 ? "删除程序" : record.new_content }}
          </div>
          <!-- 工单  学校-->
          <div v-if="record.name == '编辑学校资料'">
            <div v-if="record.new_content">
             
             <div v-if="Array.isArray(record.new_content)">
           
              <div v-if="record.new_content[record.new_content.length - 1]">
                <div
                  v-if="
                    record.new_content[record.new_content.length - 1]
                      .update_time !== ''
                  "
                >
                  <div v-for="(item, index) in record.new_content" :key="index">
                    <div v-if="item.name"><h3 style="  display: inline-block;">学校名称：</h3>{{ item.name }}</div>
                    <div v-if="item.code"><h3 style="  display: inline-block;">学校机构代码：</h3>{{ item.code }}</div>
                    <div v-if="item.ip"><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ item.ip }}</div>
                  
                  </div>
                </div>

              </div>
              <!-- 审核工单 -->
              <div
                v-if="
                  Object.keys(
                    record.new_content[record.new_content.length - 1]
                  )[0] == 'status'
                "
              >
                <div>
                  {{
                    record.new_content[0][
                      Object.keys(record.new_content[0])[0]
                    ] == 1
                      ? "审核通过"
                      : "审核不通过"
                  }}
                </div>

                <div v-if="record.new_content.length > 5">
                  {{
                    "审核意见:" +
                      record.new_content[record.new_content.length - 2][
                        Object.keys(
                          record.new_content[record.new_content.length - 2]
                        )[0]
                      ]
                  }}
                </div>
              </div>
              </div>

                 <!-- 切换 删除 权限 -->
                  <div v-else >
                    <div v-if="Object.keys(record.new_content)[0] =='scid' && Object.keys(record.new_content)[1] =='mid'">
                   
                      <h3 style="  display: inline-block;" v-if="record.new_content.auth == 0">权限：<a-tag  color="#ff4d4f" >{{mentName(record.new_content.mid).length >0 ? mentName(record.new_content.mid)[0].name :  record.new_content.mid  }}</a-tag></h3>
                      <h3 style="  display: inline-block;" v-if="record.new_content.auth == 1">权限：<a-tag  color="#2db7f5" >{{mentName(record.new_content.mid).length >0 ? mentName(record.new_content.mid)[0].name :  record.new_content.mid  }}</a-tag></h3>
                      <h3 style="  display: inline-block;" v-if="Object.keys(record.new_content).length ==2" >权限：<a-tag  > {{mentName(record.new_content.mid).length >0 ? mentName(record.new_content.mid)[0].name :  record.new_content.mid}}</a-tag>
</h3>                    </div>
                    <!-- <div v-if="Object.keys(record.new_content)[2] =='auth' ">
                      {{Object.keys(record.new_content)[2]}}
                    </div> -->
                  </div>
           

            </div>
            <div v-else>{{ record.new_content }}</div>
          </div>

          <div v-if="record.name == '添加编辑学校资料'">
            <ul>
              <!-- <li>{{ record.new_content.id }}</li> -->
              <li><h3 style="  display: inline-block;">学校名称：</h3>{{ record.new_content.name }}</li>
              <li><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ record.new_content.ip }}</li>
              <li><h3 style="  display: inline-block;">当前状态：</h3>{{ record.new_content.status == 1 ? "启用" : "停用" }}</li>
            </ul>
          </div>

          <div v-if="record.name == '修改学校状态'">
            <div v-if="Array.isArray(record.new_content)">
              <a-tag color="#ff4d4f" v-if="record.new_content[0].status == 0"
                >停用</a-tag
              >
              <a-tag color="#2db7f5" v-if="record.new_content[0].status == 1"
                >启用</a-tag
              >
              <a-tag color="orange" v-if="record.new_content[0].status == 2"
                >设置限时</a-tag
              >
              <a-tag color="red" v-if="record.new_content[0].status == 3"
                >删除程序</a-tag
              >
            </div>
            <div v-else>
              <ul>
                <!-- <li>{{ record.new_content.id }}</li> -->
                <li><h3 style="  display: inline-block;">学校名称：</h3>{{ record.new_content.name }}</li>
                <li><h3 style="  display: inline-block;">学校服务器IP：</h3>{{ record.new_content.ip }}</li>
                <li v-if="record.new_content.status == 0"><h3 style="  display: inline-block;">当前状态：</h3>停用</li>
                <li v-if="record.new_content.status == 1"><h3 style="  display: inline-block;">当前状态：</h3>启用</li>
                <li v-if="record.new_content.status == 2"><h3 style="  display: inline-block;">当前状态：</h3>设置限时</li>
                <li v-if="record.new_content.status == 3"><h3 style="  display: inline-block;">当前状态：</h3>删除程序</li>
              </ul>
            </div>
          </div>

          <div v-if="record.name == '设置学校状态'">
            <a-tag color="#ff4d4f" v-if="record.new_content[0].status == 0"
              >停用</a-tag
            >
            <a-tag color="#2db7f5" v-if="record.new_content[0].status == 1"
              >启用</a-tag
            >
            <a-tag color="orange" v-if="record.new_content[0].status == 2"
              >设置限时</a-tag
            >
            <a-tag color="orange" v-if="record.new_content[0].status == 3"
              >删除程序</a-tag
            >
          </div>
        </div>

        <div slot="admin_grade" slot-scope="record">
          <a-tag color="green">
            <span>{{ record }}</span>
          </a-tag>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "日志ID",
    dataIndex: "id",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "id" },
  },
  {
    title: "操作事项",
    dataIndex: "name",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "操作数据ID",
    dataIndex: "opid",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "opid" },
  },
  {
    title: "历史数据",
    key: "old_content",
    // align: "center",
    width: 300,
    scopedSlots: { customRender: "old_content" },
  },
  {
    title: "操作后的数据",
    key: "new_content",
    // align: "center",
    width: 300,
    scopedSlots: { customRender: "new_content" },
  },

  {
    title: "操作管理员ID",
    dataIndex: "admin_id",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "admin_id" },
  },
  {
    title: "操作管理员名字",
    dataIndex: "admin_name",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "admin_name" },
  },
  {
    title: "操作时间",
    dataIndex: "create_time",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "create_time" },
  },
  {
    title: "管理员等级",
    dataIndex: "admin_grade",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "admin_grade" },
  },
];
export default {
  name: "OperationLog",
  data() {
    return {
      loading: true, //加载
      columns, //表头
      logList: [], //表数据
      school_list: [], //学校列表
      MentData: [], //权限列表
    };
  },
  methods: {
    // 获取操作记录
    getoperationLog() {
      this.$axios
        .post(this.$apis.queryLog)
        .then((res) => {
          if (res.data.code == 200) {
            this.logList = res.data.data;
            this.$message.success("查询成功");
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 权限数据
     getMentlist(){
        this.$axios.post(this.$apis.MenuList).then(res=>{
            console.log(res,'res');
            if(res.data.code==200){
                this.MentData=res.data.data
                // this.spinning=false
            
            }else{
                //  this.spinning=false
            }
        }).catch(error=>{
            console.log(error);
        })
    },
    // 根据id查权限名称
    mentName(aItemid){
   console.log("调用");
    let name=  this.MentData.filter(item=>{
        return item.id===aItemid
      })

      console.log(name,'name');
      return name
     
    }
  },
  created() {
    this.getoperationLog();
    this.getMentlist();
  },
};
</script>

<style lang="less" scoped>
.operationLog {
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 64px - 10px - 60px);
    overflow: auto;
  }
  .a-table {
    height: auto;
    width: auto;
  }

  /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        max-height: calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
</style>
