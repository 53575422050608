import Vue from 'vue'
import VueRouter from 'vue-router'
import { RouterMap } from './rouerMap'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: RouterMap,
})
// 路由全局守卫
router.beforeEach((to, from, next) => {
	const isLogin = !!sessionStorage.userinfo
	if (to.matched.some((r) => r.meta.requireAuth)) {
		if (isLogin) {
			return next()
		} else {
			next({
				path: '/user/login',
			})
		}
	} else {
		next()
	}
})

export default router
