import axios from 'axios'
import { notification, message } from 'ant-design-vue'
const request = axios.create({
	timeout: 300000,
})

// 请求拦截
request.interceptors.request.use((config) => {
	//设置取消请求的CancelToken
	config.cancelToken = new axios.CancelToken((cancel) => {
		// 可以发送请求时添加请求头，也可以添加token值
	})

	return config
})

// 响应拦截
request.interceptors.response.use(
	(response) => {
		if (response.data.code === 401) {
			notification['error']({
				message: '请重新登录',
			})
			try {
				sessionStorage.removeItem('userinfo')
				//this.$router.replace('/user/login');
				window.location.reload()
			} catch (error) {
				//this.$router.replace('/user/login');
				window.location.reload()
			}
		}
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			notification['error']({
				message: '请重新登录',
			})
			try {
				sessionStorage.removeItem('userinfo')
				//this.$router.replace('/user/login');
				window.location.reload()
			} catch (error) {
				//this.$router.replace('/user/login');
				window.location.reload()
			}
		} else if (error.response && error.response.status === 403) {
			message.error('没有权限')
		}
		return Promise.reject(error)
	}
)

export default request
