<template>
  <div class="fillInfo">
    <a-form-model
      ref="refaddForm"
      :model="addForm"
      layout="horizontal"
      :hideRequiredMark="false"
      :label-col="{ span: 7 }"
      :wrapper-col="{ span: 17 }"
      :rules="rulesAddForm"
    >
      <a-form-model-item label="学校" prop="school_id" has-feedback>
        <!-- <a-select v-model="addForm.school_id"></a-select> -->
        <a-select v-model="addForm.school_id">
          <a-select-option
            v-for="item in SchoolList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="网络地址" prop="host" has-feedback>
        <a-input v-model="addForm.host"></a-input>
        <!-- <div>
          <a-input default-value="mysite">
            <a-select
              slot="addonBefore"
              default-value="Http://"
              style="width: 90px"
            >
              <a-select-option value="Http://"> Http:// </a-select-option>
              <a-select-option value="Https://"> Https:// </a-select-option>
            </a-select>
            <a-select
              slot="addonAfter"
              default-value=".com"
              style="width: 80px"
            >
              <a-select-option value=".com"> .com </a-select-option>
              <a-select-option value=".jp"> .jp </a-select-option>
              <a-select-option value=".cn"> .cn </a-select-option>
              <a-select-option value=".org"> .org </a-select-option>
            </a-select>
          </a-input>
        </div> -->
      </a-form-model-item>
      <a-form-model-item label="端口" prop="port" has-feedback>
        <a-input v-model="addForm.port"></a-input>
      </a-form-model-item>
      <a-form-model-item label="数据库名" prop="database" has-feedback>
        <a-input v-model="addForm.database"></a-input>
      </a-form-model-item>
      <a-form-model-item label="用户名" prop="username" has-feedback>
        <a-input v-model="addForm.username"></a-input>
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password" has-feedback>
        <a-input-password
          read-only
          v-model="addForm.password"
          onfocus="this.removeAttribute('readonly');"
        />
      </a-form-model-item>

      <a-form-model-item has-feedback>
        <div class="btn">
          <a-button key="submit" type="primary" @click="handleOk('refaddForm')">
            下一步
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SchoolList: [],
      addForm: {
        name: '',
        school_id: '',
        host: '',
        port: '',
        database: '',
        username: '',
        password: '',
      },
      rulesAddForm: {
        school_id: [
          { required: true, message: '请选择学校', trigger: 'change' },
        ],
        host: [{ required: true, message: '请输入网络地址', trigger: 'blur' }],
        port: [{ required: true, message: '请输入端口', trigger: 'blur' }],
        database: [
          { required: true, message: '请输入数据库名', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      menuList: [],
      headers: {
        authorization: 'authorization-text',
      },
    }
  },
  created() {
    let { editData } = this.$route.query
    if (editData) {
      let editForm = JSON.parse(editData)
      for (let key in this.addForm) {
        this.addForm[key] = editForm[key]
      }
      this.addForm.school_id = editForm.scid
      this.addForm.id = editForm.id
      console.log(this.addForm, ' this.addForm')
    }
    this.getSchoolList()
  },
  methods: {
    // 获取学校列表
    getSchoolList() {
      this.$axios.post(this.$apis.getSchoolList).then((res) => {
        if (res.data.code == 200) {
          this.SchoolList = res.data.data
          console.log(res.data.data, '120SchoolList')
        } else {
          this.$meaasge.error(res.data.msg)
        }
      })
    },

    // 下一步
    handleOk(FormName) {
      console.log(this.addForm, 'addForm')

      this.$refs[FormName].validate(async (item) => {
        if (item) {
          let data = this.SchoolList.find((item) => {
            return item.id == this.addForm.school_id
          })
          this.addForm.name = data.name
          this.$axios
            .post(this.$apis.addOtherSpecial, this.addForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$emit('sumbitCurrent', 1, res.data.data)
              } else {
                this.$meaasge.error(res.dta.msg)
              }
            })
          console.log(item, '15011')
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.fillInfo {
  width: 50%;
  margin: 0 auto;
  .btn {
    display: flex;
    justify-content: flex-end;
  }
}
.upload {
  position: relative;
  .uploadFile {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
</style>
