<template>
  <div class="fillInfo">
    <a-form-model
      ref="refaddForm"
      :model="addForm"
      layout="horizontal"
      :hideRequiredMark="false"
      :label-col="{ span: 7 }"
      :wrapper-col="{ span: 17 }"
      :rules="rulesAddForm"
    >
      <a-form-model-item label="专项名称" prop="groupName" has-feedback>
        <a-auto-complete
          v-model="addForm.groupName"
          :data-source="SpecialList"
          placeholder="专项名称"
          :filter-option="filterOption"
        />
      </a-form-model-item>
      <a-form-model-item label="上传模板" prop="files" has-feedback>
        <div class="upload">
          <a-button>
            <a-icon type="upload" /> 支持上传.xls .xlsx
            等格式文件，可多选</a-button
          >
          <input
            class="uploadFile"
            accept=".xls,.xlsx"
            type="file"
            :multiple="true"
            @change="changeFile($event)"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item label="专项说明" prop="model" has-feedback>
        <a-textarea :rows="8" v-model="addForm.remark" />
      </a-form-model-item>
      <a-form-model-item has-feedback>
        <div class="btn">
          <a-button key="submit" type="primary" @click="handleOk('refaddForm')">
            下一步
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SpecialList: [],
      addForm: {
        groupName: '',
        files: [],
        remark: '',
      },
      rulesAddForm: {
        groupName: [
          { required: true, message: '请输入专项名称', trigger: 'change' },
        ],
        files: [
          { required: true, message: '请上传表单模板', trigger: 'change' },
        ],
      },
      menuList: [],
      headers: {
        authorization: 'authorization-text',
      },
    }
  },
  created() {
    this.getSpecialList()
  },
  methods: {
    // 获取专项列表
    getSpecialList() {
      this.$axios.post(this.$apis.getSpecialList).then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            this.SpecialList.push(item.keyword)
          })

          console.log(res.data.data, 'data-=data')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    // 下一步
    handleOk(FormName) {
      // this.$emit('sumbitCurrent', 1)
      // return
      console.log(this.addForm, 'addForm')
      this.$refs[FormName].validate(async (item) => {
        if (item) {
          console.log(item, '15011')
          this.uploadFile().then((res) => {
            if (res.data.code == 200) {
              if (
                res.data.data == 'TRANSVERSE' ||
                res.data.data == 'PORTRAIT'
              ) {
                this.$emit('sumbitCurrent', 1)
              } else if (res.data.data == 'CROSS') {
                this.$emit('sumbitCurrent', 2)
              }

              console.log(res, 'res-=s')
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },

    filterOption(input, option) {
      console.log(input, option, 'input, option')
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      )
    },

    // 上传文件
    async uploadFile() {
      console.log(1501)
      let formData = new FormData()
      formData.append('groupName', this.addForm.groupName)
      formData.append('remark', this.addForm.remark)
      console.log(123, this.addForm.files.length)
      this.addForm.files.forEach((item) => {
        formData.append('files', item)
      })
      return await this.$axios.post(this.$apis.uploadFile, formData)
      // .then((res) => {
      //   if (res.data.code == 200) {
      //     console.log(res.data.data, 'res.data.data-=')
      //     return res.data.data
      //   } else {
      //     this.$message.error(res.data.message)
      //   }

      // })
    },
    changeFile(e) {
      let files = e.target.files
      this.addForm.files = files
    },
  },
}
</script>

<style lang="less" scoped>
.fillInfo {
  width: 50%;
  margin: 0 auto;
  .btn {
    display: flex;
    justify-content: flex-end;
  }
  .instructions {
  }
}
.upload {
  position: relative;
  .uploadFile {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
</style>
