<template>
  <div class="addschoolmenu">
    <a-modal
      :visible="addshoolMenuvisible"
      title="添加学校"
      @ok="handleOk('refaddForm')"
      @cancel="handleCancel('refaddForm')"
    >
      <a-form-model
        ref="refaddForm"
        :model="addForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="rulesAddForm"
      >
        <!-- :rules="rulesAddForm" -->
        <a-form-model-item label="权限名称" has-feedback prop="mid">
          <!-- <a-select v-model="addForm.mid"> -->
          <!-- <a-select-option v-for="item in  menulist" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option> -->
          <a-tree
            v-model="checkedKeys"
            checkable
            :tree-data="groupMenuList"
            :replace-fields="{ children: 'son', key: 'id', title: 'name' }"
            @select="onSelect"
          />
          <!-- </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="状态" has-feedback>
          <!-- <a-input v-model="addForm.name" /> -->
          <a-switch v-model="addForm.auth">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </a-form-model-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel('refaddForm')">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="handleOk('refaddForm')">
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Addschoolmenu',
  props: {
    addshoolMenuvisible: {
      type: Boolean,
      default: false,
    },
    menudata: {
      type: Object,
      default: () => {},
    },
    groupMenuList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      addForm: {
        scid: '',
        mid: '',
        auth: true,
      },
      rulesAddForm: {
        // mid: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      // menulist: [],
      checkedKeys: [],
    }
  },
  methods: {
    getMenulist() {
      this.$axios
        .post(this.$apis.MenuList)
        .then((res) => {
          if (res.data.code == 200) {
            this.menulist = res.data.data
          } else {
            return false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleOk(FormName) {
      console.log(this.checkedKeys, 'checkedKeys')
      if (this.checkedKeys.length == 0) {
        return this.$message.error('权限名称不能为空')
      } else {
        let arrid = this.checkedKeys.filter((item) => {
          return item.indexOf('父id') == -1
        })
        console.log(arrid, 'arrid')
        this.$refs[FormName].validate((item) => {
          if (item) {
            let paraobj = {
              scid: this.addForm.scid,
              mid: arrid,
              auth: this.addForm.auth == true ? 1 : 0,
            }
            console.log(this.addForm, 'this.addForm')
            this.$axios
              .post(this.$apis.addMenuSchool, paraobj)
              .then((res) => {
                if (res.data.code == 200) {
                  this.$message.success('添加学校权限成功')
                  this.handlevisi(FormName, true)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            return false
          }
        })
      }
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName)
    },

    // 关闭模态框
    handlevisi(name, bool) {
      this.addForm = {
        scid: '',
        mid: '',
        auth: true,
      }
      this.$refs[name].resetFields()
      this.$emit('handlementvisible', bool)
    },
    // onExpand(expandedKeys) {
    //   console.log('onExpand', expandedKeys)
    //   this.expandedKeys = expandedKeys
    //   this.autoExpandParent = false
    // },
    // onCheck(checkedKeys) {
    //   console.log('onCheck', checkedKeys)
    //   this.checkedKeys = checkedKeys
    // },
    onSelect(selectedKeys) {
      console.log('onSelect', selectedKeys)
      // this.selectedKeys = selectedKeys
    },
  },
  created() {
    // this.getMenulist()

    this.addForm.scid = this.menudata.id
  },
}
</script>

<style></style>
