<template>
  <div class="editschool">
    <a-modal
      :visible="editshoolvisible"
    
      title="修改学校"
      @ok="handleOk('refeditForm')"
      @cancel="handleCancel('refeditForm')"
    >
      <a-form-model
        ref="refeditForm"
        :model="editForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="ruleseditForm"
      >
        <a-form-model-item label="学校机构代码" prop="code" has-feedback>
          <!-- <a-input v-model="editForm.code" /> -->
           <a-input style="width:45%" v-model="editForm.code" />
          <a-button style=" margin-left:1%;width:45%" @click="openSchoolList"
            >选择学校机构代码</a-button
          >
        </a-form-model-item>
       <a-form-model-item label="校名" prop="name" has-feedback>
          <a-input style="width:91%" v-model="editForm.name" />
        </a-form-model-item>
       <a-form-model-item label="学校服务器IP" prop="ip" has-feedback>
          <a-input style="width:91%" v-model="editForm.ip" />
        </a-form-model-item>
       <a-form-model-item label="学校服务器端口" prop="port" has-feedback>
          <a-input style="width:91%" v-model="editForm.port" />
        </a-form-model-item>
     
       
       <!-- <a-form-model-item label="状态" has-feedback>
            <a-switch v-model="editForm.status" >
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
             </a-switch>
        </a-form-model-item> -->
        
          <template slot="footer">
          <a-button key="back" @click="handleCancel('refeditForm')"> 取消 </a-button>
          <a-button
            key="submit"
            type="primary"
          
            @click="handleOk('refeditForm')"
          >
            确定
          </a-button>
        </template>
      </a-form-model>
    </a-modal>
      <div v-if="schoolListvisible">
      <a-modal
        :visible="schoolListvisible"
        width="1000px"
        title="学校列表"
      
        @ok="handleOkList('reflistForm')"
        @cancel="handleCancelList('reflistForm')"
      >
        <a-form-model layout="horizontal" :hideRequiredMark="true">
          <!-- 搜索 -->
           <a-form-model-item has-feedback style="margin-bottom: 5px">
             <a-input  style="width:86%;margin-left:30px;text-align: center;" placeholder="请输入学校名称" @keyup.enter="search"  v-model="school_name">
                <a-icon slot="suffix" type="search" />
               </a-input><a-button  style="margin-left:10px" @click="search">搜索</a-button>
           </a-form-model-item>
          <a-form-model-item has-feedback>
            <div class="page_tabel">
              <a-table
              class="a_tabel"
            
                :row-selection="rowSelection"
                :columns="columns"
                :data-source="dataList"
                rowKey="organizationNo"
                :pagination="false"
                :loading="loading_tabel"
                   :scroll="{ x:' calc(600px)', y: 'calc(100vh - 64px - 10px - 60px - 60px - 24px - 100px - 50px)' }" 
              >
                <!-- 学校地址 -->
                <div slot="regionA" slot-scope="record">
                  {{ record.regionA }}
                  {{ record.regionB }}
                  {{ record.regionC }}
                  {{ record.regionD }}
                  {{ record.regionE }}
                </div>
              </a-table>
              <div class="pagination-container">
                <a-pagination
                  v-model="pagingnum"
                  :page-size="pagination.pagesize"
                  :total="pagination.total"
                  @change="pagtabel"
                />
              </div>
            </div>
          </a-form-model-item>
          <template slot="footer">
            <a-button key="back" @click="handleCancelList('reflistForm')">
              取消
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleOkList('reflistForm')"
            >
              确定
            </a-button>
          </template>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "学校机构代码",
    dataIndex: "organizationNo",
    key: "organizationNo",
    align: "center",
    width:40,
    scopedSlots: { customRender: "organizationNo" },
  },
  {
    title: "学校名称",
    dataIndex: "organizationName",
    key: "organizationName",
    align: "center",
     width:100,
    scopedSlots: { customRender: "organizationName" },
  },
  {
    title: "学校地址",
    // dataIndex: "regionA",
    align: "center",
     width:150,
    key: "regionA",
    scopedSlots: { customRender: "regionA" },
  },
];
export default {
  name: "Editschool",
  props: {
    editshoolvisible: {
      type: Boolean,
      default: false,
    },
    editdata: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
        schoolListvisible: false, //学校机构列表
        loading:true,
        editForm:{
            id:'',
            ip:'',
            name:'',
            code:'',
            port:'80'
            // status:true
        },
        ruleseditForm:{
            name:[{ required: true, message: '请输入校名', trigger: 'blur' }],
            ip:[{ required: true, message: '请输入学校服务器ip地址', trigger: 'blur' }],
            port:[{ required: true, message: '请输入学校服务器端口', trigger: 'blur' }],
            code:[{ required: true, message: '请输入学校机构代码', trigger: 'blur' }]
        },
          columns, //表头
      dataList: [], //表数据
         //  分页
      pagination: {
        num: 1,
        pagesize: 0,
        total: 0,
      },
      pagingnum: 1,
      selectedRows:[],//选中的数据
      school_name:'',//输入框的内容
    };
  },
  methods: {
    handleOk(FormName) {
        this.$refs[FormName].validate(item=>{
            if(item){
                let paraobj={
                    id:this.editForm.id,
                    name:this.editForm.name,
                    ip:this.editForm.ip,
                    code:this.editForm.code,
                    port:this.editForm.port,
                    // status:this.editForm.status ==true ? 1 : 0
                }
              
                this.$axios.post(this.$apis.editSchool,paraobj).then(res=>{
                    if(res.data.code==200){
                        this.$message.success('编辑成功')
                        this.handlevisi(FormName,true)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                  
                }).catch(error=>{
                    console.log(error);
                })
            }else{
                return false
            }
           
        })
    },
    // 取消
    handleCancel(FormName) {
      this.handlevisi(FormName);
    },
    // 关闭模态框
    handlevisi(name,bool) {
        this.editForm={
             name:'',
            ip:'',
            // status:true
            id:'',
            code:'',
            port:'80'
        }
         this.$refs[name].resetFields()
      this.$emit("handleeditvisible",bool);
    },
    // 
    openSchoolList(){
       this.getGDSchoolList(this.pagination.num);
      this.schoolListvisible = true;
    },
     getGDSchoolList(page, name) {
      this.loading_tabel=true
      let paraobj;
      if (name) {
        paraobj = {
          page,
          name,
        };
      } else {
        paraobj = {
          page,
        };
      }

      this.$axios
        .post(this.$apis.queryGDSchoolList, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.dataList = [];
            this.dataList = res.data.data.data;
            this.loading_tabel = false;
            this.pagination = {
              num: parseInt(res.data.data.current_page),
              total: parseInt(res.data.data.total),
              pagesize: res.data.data.per_page,
            };
          } else {
            this.$message.error(res.data.msg);
            this.loading_tabel = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
       // 选择学校
    handleOkList() {
      if(this.selectedRows.length>0){
        if(this.selectedRows.length>=2){
           this.$message.warning('只能选择一条数据')
        }else{
          this.editForm.name=this.selectedRows[0].organizationName
          this.editForm.code=this.selectedRows[0].organizationNo
       
          this.handleCancelList()
        }

      }else{
        this.$message.warning('请选择学校')
      }
    },
    // 取消
    handleCancelList() {
      this.schoolListvisible = false;
     
    },
     // 分页
    pagtabel(i) {
      if (i == this.pagination.num) {
        return;
      }
      this.getGDSchoolList(i);
    },
    // 点击搜索按钮
    search(){
      if(this.school_name.length==0){
        return
      }
      this.getGDSchoolList(1,this.school_name)
    },
  },
    computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows=selectedRows
        },
        // getCheckboxProps: (record) => ({
        //   props: {
        //     disabled: record.name === "Disabled User", // Column configuration not to be checked
        //     name: record.name,
        //   },
        // }),
      };
    },
  },
  watch: {
    school_name(i){
      if(i.length==0){
         this.getGDSchoolList(1)
      }
     
    }
  },
  created() {
      for(let key in this.editForm){
          this.editForm[key]=this.editdata[key]
      }
  },
};
</script>

<style lang="less" scoped>
.page_tabel {
  .a_tabel{
    padding: 0;
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  

    height: 45px;
  }
 
}
 /deep/ .ant-modal-body{
    padding: 0;
  }
 /deep/ .ant-row{
    margin-bottom: 0;
  }
</style>

