<template>
  <div class="workOrder">
    <div class="head">
      <h2>工单列表</h2>
    </div>
    <div class="content">
      <a-spin :spinning="spinning">
        <a-table
          class="a-tabel"
          :columns="columns"
          :data-source="Workorder"
         
          :bordered="true"
          childrenColumnName="subordinate"
          rowKey="id"
          :scroll="{
            x: true,
            y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 40px - 40px)',
          }"
        >
          <!-- :scroll="{ x: '100', y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 40px)' }" -->
          <div slot="create_time" slot-scope="record">
           {{$newdate(record * 1000)}}
          </div>
          <div slot="type" slot-scope="record">
            <a-tag v-if="record ==1">删除学校源码</a-tag>
            <a-tag v-if="record ==2">删除学校</a-tag>
          </div>
          <!-- 工单状态 -->
          <div slot="status" slot-scope="record">
            <a-tag v-if="record ==0"  color="#ff4d4f" >已撤销</a-tag>
            <a-tag v-if="record ==1" color="red">进行中</a-tag>
            <a-tag v-if="record ==2"  color="blue">已完成</a-tag>
          </div>
          <!-- <div slot="audit_1" slot-scope="record">
            <a-tag v-if="record ==0" color="orange">待审核</a-tag>
            <a-tag v-if="record ==1" color="blue">通过</a-tag>
            <a-tag v-if="record ==2" color="#ff4d4f">不通过</a-tag>
          </div>
          <div slot="audit_2" slot-scope="record">
            <a-tag v-if="record ==0" color="orange">待审核</a-tag>
            <a-tag v-if="record ==1" color="blue">通过</a-tag>
            <a-tag v-if="record ==2" color="#ff4d4f">不通过</a-tag>
          </div>
          <div slot="audit_3" slot-scope="record">
            <a-tag v-if="record ==0" color="orange">待审核</a-tag>
            <a-tag v-if="record ==1" color="blue">通过</a-tag>
            <a-tag v-if="record ==2" color="#ff4d4f">不通过</a-tag>
          </div>
          <div slot="audit_4" slot-scope="record">
            <a-tag v-if="record ==0" color="orange">待审核</a-tag>
            <a-tag v-if="record ==1" color="blue">通过</a-tag>
            <a-tag v-if="record ==2" color="#ff4d4f">不通过</a-tag>
          </div>
          <div slot="audit_5" slot-scope="record">
            <a-tag v-if="record ==0" color="orange">待审核</a-tag>
            <a-tag v-if="record ==1" color="blue">通过</a-tag>
            <a-tag v-if="record ==2" color="#ff4d4f">不通过</a-tag>
          </div> -->
          <!-- 操作 -->
          <div slot="operation" slot-scope="record">
            <a-button v-if="record.status==0 || record.status==2" disabled  style="margin:2px" size="small"  @click="audit(record)"
              >审核</a-button
            >
            <a-button v-else style="margin:2px" size="small"  @click="audit(record)"
              >审核</a-button
            >
            <div>
              <a-popconfirm
                title="是否确认撤销工单?"
                @confirm="() => revokeWorkorder(record)"
              >
                <a-button style="margin:2px" size="small">撤销工单</a-button>
              </a-popconfirm>
            </div>
             <a-button    style="margin:2px" size="small"  @click="openseed(record)"
              >审核进度</a-button
            >
             <div v-if="record.status==0">
              <a-popconfirm
                title="是否确认删除?"
                @confirm="() => DelWorkorder(record)"
              >
                <a-button type="danger" style="margin:2px" size="small">删除</a-button>
              </a-popconfirm>
            </div>
            <div v-else>
               <a-button type="danger" style="margin:2px" size="small" disabled>删除</a-button>
            </div>
          </div>
        </a-table>
      </a-spin>
    </div>

    <div v-if="auditmodal">
      <a-modal
        :visible="auditmodal"
        title="审核工单"
        @ok="handleOk('refauditForm')"
        @cancel="handleCancel('refauditForm')"
      >
        <a-form-model
          ref="refauditForm"
          :model="auditForm"
          layout="horizontal"
          :hideRequiredMark="true"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }"
          :rules="rulesAuditForm"
        >
          <a-form-model-item label="状态" has-feedback>
            <a-switch v-model="auditForm.status">
              <a-icon slot="checkedChildren" type="check" />
              <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
          </a-form-model-item>

          <a-form-model-item label="审核意见"  has-feedback>
            <a-input v-model="auditForm.opinion" />
          </a-form-model-item>
          <template slot="footer">
            <a-button key="back" @click="handleCancel('refauditForm')">
              取消
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleOk('refauditForm')"
            >
              确定
            </a-button>
          </template>
        </a-form-model>
      </a-modal>
    </div>

    <div v-if="sendProgressvisble">
      <SendProgress :sendProgressvisble="sendProgressvisble" :sendRecord="sendRecord" @handvisble="handvisble"></SendProgress>
    </div>
  </div>
</template>

<script>
import SendProgress from "./sendProgress.vue"
const columns = [
  {
    title: "工单ID",
    dataIndex: "id",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "id" },
  },
  {
    title: "操作数据ID",
    dataIndex: "opid",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "opid" },
  },
   {
    title: "操作名称",
    dataIndex: "name",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "name" },
  },
  //   {
  //   title: "操作内容",
  //   dataIndex: "content",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "content" },
  // },
  {
    title: "类型",
    dataIndex: "type",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "type" },
  },
  

 
  {
    title: "创建管理员",
    children: [
      {
        title: "ID",
        dataIndex: "create_admin_id",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "create_admin_id" },
      },
      {
        title: "名字",
        dataIndex: "create_admin_name",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "create_admin_name" },
      },
      {
        title: "等级",
        dataIndex: "create_admin_grade",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "create_admin_grade" },
      },

      {
        title: "创建时间",
        dataIndex: "create_time",
        align: "center",
        width: 150,
        scopedSlots: { customRender: "create_time" },
      },
    ],
  },

  {
    title: "工单状态",
    dataIndex: "status",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
 
  {
    title: "操作",
    align: "center",
    width: 120,
    // fixed: "right",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "WorkOrder",
  data() {
    return {
      spinning: true, //表数据加载
      loading: false, //加载
      columns, //表头数据
      Workorder: [], //表数据
      auditmodal: false, //审核模态框
      auditForm: {
        id: "",
        status: false,
        opinion: "",
      },
      rulesAuditForm: {
        opinion: [
          // { required: true, message: "请输入审核意见", trigger: "blur" },
        ],
      
      },
        sendProgressvisble:false,//审核进度模态框
        sendRecord:{},//审核进度数据
    };
  },
  components:{
    SendProgress
  },
  methods: {
    // 获取工单列表
    getQuetyWorkorder() {
      this.$axios
        .post(this.$apis.queryWorkorder)
        .then((res) => {
          if (res.data.code == 200) {
            this.Workorder = res.data.data;
            this.$message.success("查询成功");
            this.spinning = false;
          } else {
            this.$message.error(res.data.msg);
            this.spinning = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 审核
    audit(item) {
      this.auditmodal = true;
      this.auditForm.id=item.id
      console.log(item);
    },
    // 审核工单的模态框关闭
    handleCancel(formName) {
      this.auditForm = {
        id: "",
        status: false,
        opinion: "",
      };
      this.$refs[formName].resetFields();
      this.auditmodal = false;
    },
    handleOk(formName) {
      this.$refs[formName].validate((vali) => {
        if (vali) {
          let paraobj = {
            id: this.auditForm.id,
            status: this.auditForm.status == true ? 1 : 2,
            opinion:this.auditForm.opinion
          };
          this.$axios
            .post(this.$apis.auditWorkorder, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success("审核成功");
                this.handleCancel('refauditForm')
                this.getQuetyWorkorder()
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }else{
          return false
        }
      });
    },
    // 撤销工单
    revokeWorkorder(item) {
      console.log(item, "撤销工单");
      let paraobj = {
        id: item.id,
      };
      this.$axios
        .post(this.$apis.revokeWorkorder, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.getQuetyWorkorder();
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    // 删除工单
    DelWorkorder(item){
      let paraobj={
        id:item.id
      }
      this.$axios.post(this.$apis.delWorkorder,paraobj).then(res=>{
        if(res.data.code==200){
          this.$message.success('删除成功')
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(error=>{
        console.log(error);
      })

    },
    // 审核进度
    openseed(item){
      this.sendRecord=item
      this.sendProgressvisble=true
    },
    // 关闭模态款
    handvisble(){
      this.sendProgressvisble=false
    }
  },
  created() {
    this.getQuetyWorkorder();
  },
};
</script>

<style lang="less" scoped>
.workOrder {
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 64px - 10px - 60px);
    overflow: auto;
  }
  .a-table {
    height: auto;
    width: auto;
  }

  /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        max-height: calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }
}
</style>
