const RouteView = {
	name: 'RouteView',
	render: (h) => h('router-view'),
}
export const RouterMap = [
	{
		path: '/',
		component: RouteView,
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'Home',
				meta: { requireAuth: true },
				component: () => import('@/views/home/home.vue'),
				redirect: '/home/school',
				children: [
					{
						path: '/home/school',
						name: 'School',
						meta: { requireAuth: true, key_p: 'School_p' },
						component: () => import('@/views/home/school/index.vue'),
					},
					{
						path: '/home/school_list',
						name: 'School_list',
						meta: { requireAuth: true, key_p: 'School_p' },
						component: () => import('@/views/home/school_list/index.vue'),
					},
					{
						path: '/home/apply_list',
						name: 'Apply_list',
						meta: { requireAuth: true, key_p: 'School_p' },
						component: () => import('@/views/home/apply_list/index.vue'),
					},
					{
						path: '/home/access-control',
						name: 'AccessControl',
						meta: { requireAuth: true },
						component: () => import('@/views/home/access-control/index.vue'),
					},
					{
						path: '/home/user-management',
						name: 'UserManagement',
						meta: { requireAuth: true },
						component: () => import('@/views/home/user-management/index.vue'),
					},
					{
						path: '/home/work-order',
						name: 'WorkOrder',
						meta: { requireAuth: true },
						component: () => import('@/views/home/workOrder/index.vue'),
					},
					{
						path: '/home/operation-log',
						name: 'OperationLog',
						meta: { requireAuth: true },
						component: () => import('@/views/home/operationLog/index.vue'),
					},
					{
						path: '/home/userFeedback',
						name: 'UserFeedback',
						meta: { requireAuth: true },
						component: () => import('@/views/home/userFeedback/index.vue'),
					},
					{
						path: '/home/createForm',
						name: 'CreateForm',
						meta: {
							requireAuth: true,
							name: 'RPA创建表单',
							key_p: 'Form_p',
						},
						component: () => import('@/views/home/RPA/createForm/index.vue'),
					},
					{
						path: '/home/formList',
						name: 'FormList',
						meta: {
							requireAuth: true,
							name: 'RPA表单列表',
							key_p: 'Form_p',
						},
						component: () => import('@/views/home/RPA/formList/index.vue'),
					},
					{
						path: '/home/knowledgeService',
						name: 'KnowledgeService',
						meta: { requireAuth: true, name: '知识服务' },
						component: () => import('@/views/home/knowledgeService/index.vue'),
					},
					{
						path: '/home/createThirdParty',
						name: 'CreateThirdParty',
						meta: {
							requireAuth: true,
							name: '创建第三方数据',
							key_p: 'ThirdParty_p',
						},
						component: () => import('@/views/home/thirdParty/createThirdParty/index.vue'),
					},
					{
						path: '/home/thirdPartyList',
						name: 'ThirdPartyList',
						meta: {
							requireAuth: true,
							name: '第三方数据列表',
							key_p: 'ThirdParty_p',
						},
						component: () => import('@/views/home/thirdParty/thirdPartyList/index.vue'),
					},
					// {
					// 	path: '/formula-list',
					// 	name: 'FormulaList',
					// 	// redirect: '/collection/release-collection',
					// 	meta: {
					// 		name: '模板列表',
					// 		requireAuth: true,
					// 		key_p: 'formula_p',
					// 	},
					// 	component: () =>
					// 		import(
					// 			/* webpackChunkName: "role-management" */ '@/views/home/templateTable/formulaList/formulalist.vue'
					// 		),
					// },
					// {
					// 	path: '/fill-formula',
					// 	name: 'Fillformula',
					// 	// redirect: '/collection/release-collection',
					// 	meta: {
					// 		name: '填写公式',
					// 		requireAuth: true,
					// 		key_p: 'formula_p',
					// 	},
					// 	component: () =>
					// 		import(
					// 			/* webpackChunkName: "role-management" */ '@/views/home/templateTable/formulaList/fillformula.vue'
					// 		),
					// },
					// {
					// 	path: '/show-list',
					// 	name: 'ShowList',
					// 	meta: {
					// 		name: '审核列表',
					// 		requireAuth: true,
					// 		key_p: 'formula_p',
					// 	},
					// 	component: () =>
					// 		import(
					// 			/* webpackChunkName: "role-management" */ '@/views/home/templateTable/formulaList/fillformula.vue'
					// 		),
					// },
				],
			},
		],
	},

	{
		path: '/user/login',
		name: 'Login',
		meta: { requireAuth: false },
		component: () => import('@/views/user/login.vue'),
	},
]
