<template>
  <div class="knowledgeService">
    <div class="search">
      <a-row>
        <a-col :span="7">
          <span class="title">学校关键词</span>
          <a-input style="width: 70%" v-model="searchData.school"></a-input>
        </a-col>
        <a-col :span="7">
          <span class="title">服务标题</span>
          <a-input style="width: 80%" v-model="searchData.title"></a-input>
        </a-col>
        <a-col :span="5">
          <span class="title">状态</span>
          <a-select style="width: 80%" v-model="searchData.status">
            <a-select-option :value="-1"> 全部 </a-select-option>
            <a-select-option :value="0"> 审批通过 </a-select-option>
            <a-select-option :value="1"> 待处理 </a-select-option>
            <a-select-option :value="2"> 处理完成 </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5">
          <a-button
            class="title"
            size="small"
            icon="search"
            type="primary"
            @click="getServiceList"
            >查询</a-button
          >
          <a-button size="small" icon="undo" @click="resetClick">重置</a-button>
        </a-col>
      </a-row>
    </div>
    <a-table
      :columns="columns"
      :data-source="ServiceList"
      :loading="loading"
      rowKey="id"
      bordered
    >
      <template slot="user_name" slot-scope="text, record">
        <span>{{ record.user_name }} - {{ record.user_id }}</span>
      </template>
      <template slot="files" slot-scope="record">
        <a
          v-for="(item, index) in record"
          :key="index"
          :href="item.fileUrl"
          target="_blank"
        ><a-tag
            style="margin: 2px auto" color="blue" ><a-icon type="file" /> {{ item.fileName }}</a-tag><br /></a
        >
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag
          :color="
            text == 0
              ? 'pink'
              : text == 1
              ? 'orange'
              : text == 2
              ? '#108ee9'
              : 'red'
          "
        >
          {{
            text == 0
              ? '审批通过'
              : text == 1
              ? '待处理'
              : text == 2
              ? '处理完成'
              : ''
          }}
        </a-tag>
      </template>
      <span slot="operation" slot-scope="record">
        <a-popconfirm
          title="是否处理完成"
          ok-text="是"
          cancel-text="创建表单"
          @confirm="handleService(record, 2)"
          @cancel="createForm(record)"
        >
          <a-button style="margin-left: 2px">处理</a-button>
        </a-popconfirm>
        <a-button style="margin-left: 2px" :disabled="JSON.stringify(record.logs) === '[]'" @click="seeLogs(record)"
          >查看审批</a-button
        >
      </span>
    </a-table>

    <a-modal
      :visible="visible"
      title="审批日志"
      @cancel="handleCancel"
      :footer="false"
    >
      <a-empty v-if="logosData.length == 0" />
      <a-timeline v-else>
        <a-timeline-item v-for="(item, key) in logosData" :key="key">
          {{ item.user_name }}- {{ item.user_id }}

          <div class="remack">
            <div class="box">{{ item.remark }}</div>
            <a
              v-for="(item_s, index_s) in item.files"
              :key="index_s"
              :href="item_s.fileUrl"
              target="_blank"
              >{{ item_s.fileName }}</a
            >
          </div>
        </a-timeline-item>
      </a-timeline>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    width: 50,
  },
  {
    title: '申请人',
    dataIndex: 'user_name',
    key: 'user_name',
    scopedSlots: { customRender: 'user_name' },
    width: 100,
  },
  {
    title: '学校',
    dataIndex: 'school_name',
    key: 'school_name',
    scopedSlots: { customRender: 'school_name' },
    width: 100,
  },
  {
    title: '部门',
    dataIndex: 'bmmc',
    key: 'bmmc',
    scopedSlots: { customRender: 'bmmc' },
    width: 100,
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
    width: 100,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
    width: 100,
  },
  {
    title: '附件',
    dataIndex: 'files',
    key: 'files',
    scopedSlots: { customRender: 'files' },
    width: 100,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    width: 100,
  },
  {
    title: '时间',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
    width: 100,
  },

  {
    title: '操作',
    // dataIndex: 'operation',
    key: 'operation',

    scopedSlots: { customRender: 'operation' },
    width: 100,
  },
]

export default {
  name: 'ServiceList',
  data() {
    return {
      loading: true,
      visible: false,
      columns,
      ServiceList: [],
      logosData: {}, //审批日志
      userInfo: {},
      searchData: {
        school: '',
        title: '',
        status: -1,
      },
    }
  },
  created() {
    this.getServiceList()
  },
  methods: {
    // 获取服务列表
    getServiceList() {
      let params = {
        school: this.searchData.school,
        title: this.searchData.title,
        status: this.searchData.status == -1 ? '' : this.searchData.status,
      }

      this.$axios.post(this.$apis.getServiceList, params).then((res) => {
        if (res.data.code == 200) {
          this.ServiceList = res.data.data
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.data.msg)
        }
      })
    },

    // 处理服务
    handleService(item, i) {
      let params = {
        status: i,
        serviceId: item.id,
      }
      this.$axios.post(this.$apis.handleService, params).then((res) => {
        if (res.data.code == 200) {
          this.getServiceList()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    // 跳转创建表单页
    createForm(record) {
       let token =this.$md5(record.school_id) 
       let bmId_serviceId=JSON.stringify( {
        bmId:record.bmbh,
        serviceId:record.id
       })
      //  window.open(
      //       `http://192.168.0.117:5001/login?token=${token}&type=2&key=/Admin/createForm?bmId_serviceId=${bmId_serviceId}`
      //   )
       window.open(
          `http://${record.ip}:${record.port}/login?token=${token}&type=2&key=/Admin/createForm?bmId_serviceId=${bmId_serviceId}`
        )
      return 
      this.loading = true
      let id = record.school_id
      this.$axios.post(this.$apis.Url_log).then((res) => {
        let token = this.$md5(id)
        this.loading = false
        window.open(
          `${res.data.url_local_0}login?token=${token}&type=2&key=/Admin/createForm`
        )
        // `${res.data.url_local_5009}user/login?token=${token}?key=${item.key}`
      })
    },
 
    // 获取学校的ip地址
    getSchool_ip(s_id){


    },

    // 关闭模态款
    handleCancel() {
      this.logosData = ''
      this.visible = false
    },
    // 查看审批日志
    seeLogs(record) {
      this.logosData = record.logs
      console.log(this.logosData, ' this.logosData ')
      this.visible = true
    },
    resetClick() {
      this.searchData = {
        school: '',
        title: '',
        status: -1,
      }
      this.getServiceList()
    },
  },
}
</script>

<style lang="less" scoped>
.knowledgeService {
  padding: 24px;
  width: 100%;
  background-color: #fff;
}
.search {
  margin: 10px 0;
  .title {
    margin: 0 10px;
  }
}
.list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.remack {
  background-color: #f3f3f3;
  .box {
    color: #2b2b2b;
    text-indent: 2em;
    font-size: 12px;
    padding: 10px;
    margin-bottom: 10px;
  }
  a {
    padding-left: 5px;
  }
}
</style>
