<template>
  <div class="formList">
    <div class="head">
      <h2>第三方数据列表</h2>
    </div>

    <div class="btnList">
      <a-button type="primary" size="small" icon="plus" @click="createClick"
        >创建</a-button
      >
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :loading="loading"
        rowKey="id"
      >
        <template slot="operation" slot-scope="record, text">
          <a-button size="small" type="primary" @click="editClick(record, text)"
            >编辑</a-button
          >
          
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    width: 100,
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    width: 100,
  },
  {
    title: '学校ID',
    dataIndex: 'scid',
    key: 'scid',
    scopedSlots: { customRender: 'scid' },
    width: 100,
  },
  {
    title: '数据库主机',
    dataIndex: 'host',
    key: 'host',
    scopedSlots: { customRender: 'host' },
    width: 100,
  },
  {
    title: '数据库端口',
    dataIndex: 'port',
    key: 'port',
    scopedSlots: { customRender: 'port' },
    width: 100,
  },
  {
    title: '数据库名',
    dataIndex: 'database',
    key: 'database',
    scopedSlots: { customRender: 'database' },
    width: 100,
  },
  {
    title: '用户名',
    dataIndex: 'username',
    key: 'username',
    scopedSlots: { customRender: 'username' },
    width: 100,
  },

  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 100,
  },
]
export default {
  name: 'FormList',
  data() {
    return {
      loading: true,
      columns,
      dataList: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$axios.get(this.$apis.getOtherSpecialList).then((res) => {
        if (res.data.code == 200) {
          this.loading = false
          this.dataList = res.data.data
          console.log('150', res.data.data)
        } else {
          this.loading = false
          this.$message.error(res.data.message)
        }
      })
    },
    editClick(record, text) {
      this.$router.push({
        path: '/home/createThirdParty',
        query: {
          editData: JSON.stringify(text),
        },
      })
    },
    disable(record,text){
      console.log(text,'text');
    },

    // 创建
    createClick() {
      this.$router.push('/home/createThirdParty')
    },
  },
}
</script>

<style lang="less" scoped>
.formList {
  padding: 24px;
  .head {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .serachForm {
    .list {
      display: flex;
      .left {
        flex: 1;
      }
      .right {
        display: flex;
        flex-direction: column;
        .btn {
          margin-bottom: 5px;
        }
      }
    }
  }
  .btnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 45px;
}
</style>