<template>
  <div class="dataTable">
    <a-checkbox @change="onChange"> 全选 </a-checkbox>

    <ul class="ul">
      <li
        v-for="item in SpecialList"
        :key="item.i"
        @change="checkboxChange(item.CHECKED, item.i)"
      >
        <div>
          <a-checkbox v-model="item.CHECKED">
            {{ item.TABLE_COMMENT }} - {{ item.TABLE_NAME }}
          </a-checkbox>
        </div>
      </li>
    </ul>
    <div class="save">
      <a-button type="primary" @click="step_p" style="margin-right: 10px"
        >上一步</a-button
      >
      <a-button type="primary" :loading="loading" @click="save">提交</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    schoolInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      List: [],
      SpecialList: [],
    }
  },
  created() {
    this.getDataTableList()
  },
  methods: {
    step_p() {
      this.$emit('sumbitCurrent', 0)
    },
    getDataTableList() {
      let params = {
        // id: 5,
        // scid: 'd1e6ed72e2fa8c24677cbd7548a53f5b',
        id: this.schoolInfo.id,
        scid: this.schoolInfo.scid,
      }
      this.$axios.post(this.$apis.getEachOtherSpecial, params).then((res) => {
        if (res.data.code == 200) {
          this.SpecialList = res.data.data.map((item, index) => {
            item.i = index

            return item
          })
          console.log(res, 'res-=')
        } else {
          this.$message.error(res.data.msg)
          return false
        }
      })
    },
    // 提交
    save() {
      this.loading = true
      let params_1 = this.SpecialList.filter((item) => {
        return item.CHECKED
      }).map((item_m) => {
        let { i, ...rest } = item_m
        return rest
      })
      let params = {
        tables: params_1,
      }
      this.$axios.post(this.$apis.saveUsedTables, params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('sumbitCurrent', 0, {})
          this.loading = false
        } else {
          this.$message.error(res.data.msg)
          this.loading = false
          return false
        }
      })
    },
    // 全选
    onChange(e) {
      if (e.target.checked) {
        this.SpecialList.forEach((item) => {
          item.CHECKED = true
        })
      } else {
        this.SpecialList.forEach((item) => {
          item.CHECKED = false
        })
      }
    },
    //
    checkboxChange(flag, i) {
      if (flag) {
        this.SpecialList[i].CHECKED = true
        console.log(this.SpecialList, 'this.SpecialList')
      } else {
        this.SpecialList[i].CHECKED = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.dataTable {
  text-align: left;
  box-sizing: border-box;
  padding: 10px;
  margin-left: 50px;
}
ul {
  height: calc(100vh - 70px - 100px - 50px - 50px);
  overflow: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  li {
    padding: 5px 0;
    cursor: pointer;
  }
}
.save {
  text-align: center;
  margin-top: 10px;
}
</style>