<template>
  <div class="matchField">
    <div class="tabs">
    
      <a-tabs :activeKey="activeKey" type="editable-card" @edit="onEdit" @change="callback">
        <a-tab-pane
          v-for="(item,index) in tableList"
          :key="index"
          :tab="item.templateName"
        >
         <a-table
        :columns="columns"
        :data-source="item.columnsConfigTmpDTOList"
        :pagination="false"
         rowKey="id"
      >
      <!-- 字段名称 -->
            <template slot="columnNames" slot-scope="record,data,index">
             <a-auto-complete
              style="width: 120px"
                v-model="data.columnNames"
                :data-source="data.columnNames_v"
              />
            </template>
            <!-- 字段类型 -->
            <template slot="columnTypes"  slot-scope="record,data,index">
              <a-select
              v-model="data.columnTypes"
                style="width: 120px"
              >

                <a-select-option value=" varchar(255)"> varchar(255)</a-select-option>
                <a-select-option value="char(255)">char(255) </a-select-option>
                <a-select-option value="int(11)">int(11) </a-select-option>
                <a-select-option value="bigint(19)"> bigint(19)</a-select-option>
                <a-select-option value="tinyint(1)">tinyint(1) </a-select-option>
                <a-select-option value="text(0)"> text(0)</a-select-option>
                <a-select-option value="longtext(0)"> longtext(0)</a-select-option>
                <a-select-option value="datetime(0)"> datetime(0)</a-select-option>
              </a-select>
            </template>
           
             <!-- 是否为允许空值 -->
            <template slot="requireds" slot-scope="record,data,index">
              <a-select
                v-model="data.requireds"
                style="width: 120px"
              >
                <a-select-option value="是(1)"> 是(1) </a-select-option>

                <a-select-option value="否(0)"> 否(0) </a-select-option>
              </a-select>
            </template>
             <!-- 字段默认值 -->
             <template
              slot="columnDefaultValues"
              slot-scope="record,data,index"
            >
                 <a-auto-complete
                  style="width: 120px"
                v-model="data.columnDefaultValues"
                :data-source="data.columnDefaultValues_v"
              />
            </template>
             <!-- 索引 -->
            <template slot="indexs" slot-scope="record,data,index">
              <a-select
              v-model="data.indexs"
                style="width: 120px"
              >

                <a-select-option value="PRIMARY"> PRIMARY </a-select-option>
                <a-select-option value="UNIQUE"> UNIQUE </a-select-option>
                <a-select-option value="KEY"> KEY </a-select-option>
              </a-select>
            </template>
               <!-- 输入框类型 -->
            <template slot="inputTypes" slot-scope="record,data,index">
              <a-select
               v-model="data.inputTypes"
                style="width: 120px"
              >
                <a-select-option value="input"> input </a-select-option>
                <a-select-option value="select"> select </a-select-option>
                <a-select-option value="checkout"> checkout </a-select-option>
                <a-select-option value="radio"> radio </a-select-option>
              </a-select>
            </template>
             <!-- 文本字段时，具体字段类型 -->
            <template slot="inputDataTypes"  slot-scope="record,data,index">
              <a-select
              v-model="data.inputDataTypes"
                style="width: 120px"
              >
                <a-select-option value="text"> text </a-select-option>
                <a-select-option value="number"> number </a-select-option>
                <a-select-option value="date-YYYY">date-YYYY</a-select-option>
                <a-select-option value="date-YYYYMM">date-YYYYMM </a-select-option>
                <a-select-option value="date-YYYYMMDD">date-YYYYMMDD </a-select-option>
              </a-select>
            </template>
             <!-- 宽度 -->
            <template slot="widths" slot-scope="record,data,index">
            
               <a-input v-model="data.widths"></a-input> 
            </template>

             <!-- 可否排序 -->
            <template slot="sorters" slot-scope="record,data,index">
           
              <a-select
            v-model="data.sorters"
                style="width: 120px"
              >
                <a-select-option value="是(1)">是(1) </a-select-option>
                <a-select-option value="否(0)">否(0) </a-select-option>
              </a-select>
            </template>
      
      </a-table>
          
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="footer">
      <a-button key="submit" style="margin: 0 10px" @click="handlecance()">
        上一步
      </a-button>
      <a-button key="submit1" type="primary" @click="handleOk">
        <!-- v-show="activeKey !== tableList.length" -->
        下一步
      </a-button>
    </div>
     <a-modal
      :visible="visible"
      title="确认提示"
      @cancel="handleCancel"
      @ok="editUpdate"
    >
     <h3>是否确认修改完成</h3>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: '字段注释',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'title' },
    fixed: 'left',
    width: 100,
  },
  {
    title: '字段名称',
    dataIndex: 'columnNames',
    key: 'columnNames',
    scopedSlots: { customRender: 'columnNames' },
    width: 100,
  },
  {
    title: '字段类型',
    dataIndex: 'columnTypes',
    key: 'columnTypes',
    scopedSlots: { customRender: 'columnTypes' },
  },

  {
    title: '是否允许为空',
    dataIndex: 'requireds',
    key: 'requireds',
    scopedSlots: { customRender: 'requireds' },
  },
  {
    title: '字段默认值',
    dataIndex: 'columnDefaultValues',
    key: 'columnDefaultValues',
    scopedSlots: { customRender: 'columnDefaultValues' },
  },
  {
    title: '索引',
    dataIndex: 'indexs',
    key: 'indexs',
    scopedSlots: { customRender: 'indexs' },
  },
  {
    title: '输入框类型',
    dataIndex: 'inputTypes',
    key: 'inputTypes',
    scopedSlots: { customRender: 'inputTypes' },
  },
  {
    title: '文本字段时，具体字段类型',
    dataIndex: 'inputDataTypes',
    key: 'inputDataTypes',
    scopedSlots: { customRender: 'inputDataTypes' },
  },
  {
    title: '宽度',
    dataIndex: 'widths',
    key: 'widths',
    scopedSlots: { customRender: 'widths' },
  },
  {
    title: '可否排序',
    dataIndex: 'sorters',
    key: 'sorters',
    scopedSlots: { customRender: 'sorters' },
  },
]
export default {
  name: '',
  data() {
    return {
      visible: false,
      activeKey: 0, //当前激活的面版
      columns,
      tableList: [],
    }
  },
  created() {
    this.getTranserseHeaderList()
  },
  methods: {
    callback(value) {
      this.activeKey = value
      console.log(value, 'value')
    },
    // 上一步
    handlecance() {
      if (this.activeKey == 0) {
        this.$emit('sumbitCurrent', 0)
      } else {
        this.activeKey -= 1
      }
    },
    // 下一步
    async handleOk() {
      if (this.activeKey == this.tableList.length - 1) {
        // this.editUpdate()
        // this.$emit('sumbitCurrent', 2)
        this.visible = true

        // 处理
      } else {
        this.activeKey += 1
      }
    },
    handleCancel() {
      this.visible = false
    },

    // 编辑
    editUpdate() {
      let params = this.tableList.map((item) => {
        let columnsConfigTmpDTOList = item.columnsConfigTmpDTOList.map((it) => {
          let { columnDefaultValues_v, columnNames_v, ...rest } = it
          return rest
        })
        let obj = {
          tableData: columnsConfigTmpDTOList,
          tableId: item.tableId,
          templateName: item.templateName,
        }
        return obj
      })

      console.log(params)
      this.$axios.put(this.$apis.updateHeaderList, params).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data, '120')
        } else {
          this.$message.error(res.data.message)
          return false
        }
      })
    },
    // get/shufu/getTranserseHeaderList
    getTranserseHeaderList() {
      this.$axios.get(this.$apis.getTranserseHeaderList).then((res) => {
        if (res.data.code == 200) {
          this.handleData(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
        console.log(res, 'getTranserseHeaderList')
      })
    },
    handleData(array) {
      array.forEach(async (item) => {
        item.columnsConfigTmpDTOList = await this.filterArrOnj(
          item.columnsConfigTmpDTOList
        )
        let { tableId, templateName, ...rest } = item
        let columnsConfigTmpDTOList = rest.columnsConfigTmpDTOList.map(
          (item) => {
            let obj = {}
            for (let key in item) {
              if (Array.isArray(item[key])) {
                if (key == 'columnNames' || key == 'columnDefaultValues') {
                  let obj_v = `${key}_v`
                  // 去重与去空
                  obj[obj_v] = [...new Set(item[key])].filter((it) => it !== '')
                  obj[key] = item[key][0]
                } else {
                  obj[key] = item[key][0]
                }
              } else {
                obj[key] = item[key]
              }
            }
            return obj
          }
        )
        let objInfo = {}
        objInfo.tableId = tableId
        objInfo.templateName = templateName
        objInfo.columnsConfigTmpDTOList = columnsConfigTmpDTOList
        console.log(objInfo, 'objInfo')
        this.tableList.push(objInfo)
      })
    },
    // 数组对象去重
    filterArrOnj(person) {
      let obj = {}
      let peon = person.reduce((cur, next) => {
        obj[next.title] ? '' : (obj[next.title] = true && cur.push(next))
        return cur
      }, [])
      return peon
    },
    // 删除tab
    onEdit(val) {
      if (this.tableList.length == 1) return
      this.tableList.splice(val, 1)
      console.log(val, 'val')
    },
    // 可输入
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      )
    },
  },
}
</script>

<style lang="less" scoped>
.matchField {
  margin: 0 20px;
}
/deep/.ant-tabs-nav-scroll {
  text-align: left;
}
.tabs {
  margin-bottom: 30px;
}
</style>
