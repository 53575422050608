<template>
  <div class="formList">
    <div class="head">
      <h2>表单列表</h2>
    </div>
    <!-- <div class="serachForm">
      <header>查询表单</header>
      <div class="list">
        <div class="left">
          <a-row>
            <a-col :span="8">
              <span>模板名称</span>
            </a-col>
            <a-col :span="8"> col-8 </a-col>
            <a-col :span="8"> col-8 </a-col>
          </a-row>
        </div>
          <a-row>
            <a-col :span="8"> col-8 </a-col>
            <a-col :span="8"> col-8 </a-col>
            <a-col :span="8"> col-8 </a-col>
          </a-row>
        </div>
        <div class="right">
          <a-button class="btn" size="small" icon="search" type="primary"
            >查询</a-button
          >
          <a-button size="small" icon="undo">重置</a-button>
        </div>
      </div>
    </div> -->
    <div class="btnList">
      <a-button type="primary" size="small" icon="plus" @click="createClick"
        >创建</a-button
      >
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        rowKey="id"
      >
        <template slot="handleStatus" slot-scope="record">
          <span>{{ record == 0 ? '处理中' : '已上线' }}</span>
        </template>
        <template slot="tableType" slot-scope="record">
          <span>{{
            record == 'TRANSVERSE'
              ? '横向表'
              : record == 'PORTRAIT'
              ? '纵向表'
              : record == 'CROSS'
              ? '交叉表'
              : ''
          }}</span>
        </template>
        <template slot="createTime" slot-scope="record">
          <div>{{ record | handleDate }}</div>
        </template>
      </a-table>
      <div class="pagination-container">
        <a-pagination
          v-model="pagingnum"
          :page-size="pagination.size"
          :total="pagination.total"
          @change="pagtabel"
        />
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '模板名称',
    dataIndex: 'templateName',
    key: 'templateName',
    scopedSlots: { customRender: 'templateName' },
    width: 100,
  },
  {
    title: '数据表名称',
    dataIndex: 'tableName',
    key: 'tableName',
    scopedSlots: { customRender: 'tableName' },
    width: 100,
  },
  {
    title: '专项名称',
    dataIndex: 'groupName',
    key: 'groupName',
    scopedSlots: { customRender: 'groupName' },
    width: 100,
  },
  {
    title: '表格类型',
    dataIndex: 'tableType',
    key: 'tableType',
    scopedSlots: { customRender: 'tableType' },
    width: 100,
  },
  {
    title: '字段数量',
    dataIndex: 'columnNum',
    key: 'columnNum',
    scopedSlots: { customRender: 'columnNum' },
    width: 100,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    width: 100,
  },
  {
    title: '状态',
    dataIndex: 'handleStatus',
    key: 'handleStatus',
    scopedSlots: { customRender: 'handleStatus' },
    width: 100,
  },
  {
    title: '说明',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
    width: 100,
  },
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   key: 'operation',
  //   scopedSlots: { customRender: 'operation' },
  //   width: 100,
  // },
]
export default {
  name: 'FormList',
  data() {
    return {
      columns,
      dataList: [],
      pagingnum: 1,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
    }
  },
  created() {
    this.getData(this.pagination)
  },
  methods: {
    getData(pagination) {
      let params = {
        current: pagination.current,
        size: pagination.size,
      }
      this.$axios.get(this.$apis.getUploadTables, { params }).then((res) => {
        if (res.data.code == 200) {
          this.dataList = res.data.data.records
          this.pagination.total = res.data.data.total
          console.log('150', this.dataList)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    pagtabel(i) {
      if (i == this.pagination.current) {
        return
      } else {
        this.pagination.current = i
        this.getData(this.pagination)
      }
    },
    // 创建
    createClick() {
      this.$router.push('/home/createForm')
    },
  },
  filters: {
    handleDate(date) {
      return date.replace('T', ' ')
    },
  },
}
</script>

<style lang="less" scoped>
.formList {
  padding: 24px;
  .head {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .serachForm {
    .list {
      display: flex;
      .left {
        flex: 1;
      }
      .right {
        display: flex;
        flex-direction: column;
        .btn {
          margin-bottom: 5px;
        }
      }
    }
  }
  .btnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .table {
  }
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 45px;
}
</style>