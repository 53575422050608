import Vue from "vue";
import {
  Button,
  message,
  notification,
  Modal,
  ConfigProvider,
  Form,
  Input,
  Icon,
  FormModel,
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Tag,
  Table,
  Switch,
  Spin,
  Select,
  DatePicker,
  TimePicker,
  Pagination,
  Popconfirm,
  Tooltip,
  Drawer,
  Steps,
  List,
  Tree,
  Collapse,
  Tabs,
  Result,
  Row,
  Col,
  Upload,
  AutoComplete,
  Timeline,
  Empty,
  Checkbox,
  Radio,
  Badge,

  // Collapsepanel,
} from "ant-design-vue";

Vue.use(Button);
Vue.use(ConfigProvider);
Vue.use(Form);
Vue.use(Input);
Vue.use(Icon);
Vue.use(FormModel);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Avatar);
Vue.use(Tag);
Vue.use(Modal);
Vue.use(Table);
Vue.use(Switch);
Vue.use(Spin);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Pagination);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Drawer);
Vue.use(Steps);
Vue.use(List);
Vue.use(Tree);
Vue.use(Collapse);
Vue.use(Tabs);
Vue.use(Result);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(AutoComplete);
Vue.use(Timeline);
Vue.use(Empty);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Badge);

// Vue.use(Collapsepanel)

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$modal = Modal;
