<template>
  <div class="accessControl">
    <div class="head">
      <h2 @click="addshoolvisible = true">权限菜单</h2>
      <div>
        <a-button @click="addshoolvisible = true" type="primary" :ghost="true"
          ><a-icon type="plus" />添加权限</a-button
        >
      </div>
    </div>
    <div class="content">
      <a-spin :spinning="spinning">
        <a-table
          class="a-table"
          :columns="columns"
          :data-source="MentData"
          rowKey="id"
          :scroll="{
            x: true,
            y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 56px)',
          }"
        >
          <!-- 状态 -->
          <div slot="status" slot-scope="record">
            <a-tag
              color="#ff4d4f"
              style="cursor: pointer"
              v-if="record.status == 0"
              @click="onchangestatus(record)"
            >
              停用
            </a-tag>
            <a-tag
              color="#2db7f5"
              style="cursor: pointer"
              v-if="record.status == 1"
              @click="onchangestatus(record)"
            >
              启用
            </a-tag>
            <a-tag
              color="#87d068"
              style="cursor: pointer"
              v-if="record.status == 2"
              @click="onchangestatus(record)"
            >
              维护
            </a-tag>
          </div>
          <div slot="operation" slot-scope="record">
            <span class="edit" style="padding: 0 10px">
              <a-icon @click="editMenu(record)" type="edit"
            /></span>
            <a-popconfirm
              title="是否确认删除?"
              @confirm="() => delMenu(record)"
            >
              <span class="del" style="padding: 0 10px">
                <a-icon type="delete"
              /></span>
            </a-popconfirm>
          </div>
        </a-table>
      </a-spin>
    </div>
    <div v-if="addshoolvisible">
      <Addaccess
        :addshoolvisible="addshoolvisible"
        @handleaddvisible="handlevisible"
        :menuList="menuList"
      ></Addaccess>
    </div>

    <div v-if="editshoolvisible">
      <Editaccess
        :editshoolvisible="editshoolvisible"
        @handleeditvisible="handlevisible"
        :editdata="editdata"
        :menuList="menuList"
      ></Editaccess>
    </div>
  </div>
</template>

<script>
import Addaccess from './addaccess.vue'
import Editaccess from './editaccess.vue'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    width: '10%',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '模块名称',
    dataIndex: 'model',
    width: '20%',
    scopedSlots: { customRender: 'model' },
  },

  {
    title: '权限名称',
    dataIndex: 'name',
    width: '20%',
    scopedSlots: { customRender: 'name' },
  },

  {
    title: '状态(单击切换状态)',
    // dataIndex: 'status',
    key: 'status',
    width: '15%',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    width: '15%',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'accessControl',
  components: {
    Addaccess,
    Editaccess,
  },
  data() {
    return {
      spinning: true, //加载
      addshoolvisible: false, //添加权限模态框
      editshoolvisible: false, //添加权限模态框
      columns, //表头
      MentData: [], //表数据
      editdata: {},
      menuList: [],
    }
  },
  methods: {
    // 获取权限菜单
    getMentlist() {
      this.$axios
        .post(this.$apis.MenuList)
        .then((res) => {
          console.log(res, 'res')
          if (res.data.code == 200) {
            this.MentData = res.data.data
            this.spinning = false
          } else {
            this.spinning = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 关闭模态框
    handlevisible(bool) {
      this.addshoolvisible = false
      this.editshoolvisible = false
      if (bool == true) {
        this.getMentlist()
      }
    },
    // 删除权限
    delMenu(it) {
      this.spinning = true
      let paraobj = {
        id: it.id,
      }
      this.$axios.post(this.$apis.delMenu, paraobj).then((res) => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getMentlist()
          this.spinning = false
        } else {
          this.$message.error(res.data.msg)
          this.spinning = false
        }
      })
    },
    // 修改权限菜单
    editMenu(it) {
      this.editdata = it
      this.editshoolvisible = true
    },
    // 修改权限菜单状态
    onchangestatus(record) {
      this.spinning = true

      console.log(record, 'record')
      let paraobj = {
        id: record.id,
        status: record.status >= 2 ? 0 : record.status + 1,
      }
      this.$axios
        .post(this.$apis.statusMenu, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success('设置状态成功')
            this.getMentlist()
            this.spinning = false
          } else {
            this.$message.error('设置状态失败')
            this.spinning = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMenuList() {
      this.$axios.post(this.$apis.menuOptionsList).then((res) => {
        if (res.data.code == 200) {
          this.menuList = res.data.data
        }
      })
    },
    // 获取权限列表
  },
  created() {
    this.getMenuList()
    this.getMentlist()
  },
}
</script>

<style lang='less' scoped>
.accessControl {
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 64px - 10px - 60px);
    overflow: auto;
  }
  .a-table {
    height: auto;
  }

  /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        // max-height:  calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }

  .edit:hover {
    .anticon-edit {
      // font-size: 20px;
      color: rgb(14, 14, 248);
    }
  }
  .del:hover {
    .anticon-delete {
      // font-size: 20px;
      color: rgb(251, 47, 47);
    }
  }
}
</style>