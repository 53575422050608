<template>
  <div class="userManagement">
    <div class="head">
       <h2>学校列表</h2>
            <div><a-button @click="adduser" type="primary" :ghost="true"><a-icon type="plus" />添加下级用户</a-button></div>
    </div>
    <div class="content">
      <a-table
      class="a-tabel"
        :columns="columns"
        :data-source="userData"
        :loading="loading"
        childrenColumnName="subordinate"
        rowKey="id"
         :scroll="{ x: true, y: ' calc(100vh - 64px - 10px - 60px - 60px - 24px - 36px)' }"
      >
        <div slot="status" slot-scope="record">
            <a-tag color="#2db7f5" v-if="record ==1">
                启用
            </a-tag>
            <a-tag color="#ff4d4f" v-if="record ==0">
                停用
            </a-tag>
        </div>
        <div slot="grade" slot-scope="record">
            <a-tag color="green" >
                <span >{{record}}</span>
                
            </a-tag>
        </div>
        <div slot="create_time" slot-scope="record">
          {{$newdate(record*1000)}}
        </div>
        <!-- <div slot="operation" slot-scope="record">
          <a-button style="margin:2px" size="small" @click="adduser(record)"
            >添加下级用户</a-button
          >
        </div> -->
      </a-table>
    </div>

    <a-modal :visible="childrenUserVisibel" title="添加下级用户"
       @ok="handleOk('refaddForm')"
      @cancel="handleCancel('refaddForm')">
        <a-form-model
        ref="refaddForm"
        :model="addForm"
        layout="horizontal"
        :hideRequiredMark="true"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :rules="rulesAddForm"
      >
        <a-form-model-item label="用户名" prop="name" has-feedback>
          <a-input v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="账号" prop="phone" has-feedback>
          <a-input v-model="addForm.phone" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
          <a-button key="back" @click="handleCancel('refaddForm')"> 取消 </a-button>
          <a-button
            key="submit"
            type="primary"
          
            @click="handleOk('refaddForm')"
          >
            确定
          </a-button>
      </template>

    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
    width: "15%",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "用户名",
    dataIndex: "name",
    align: "center",
    width: "15%",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "手机号",
    dataIndex: "phone",
    align: "center",
    width: "10%",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    width: "10%",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "等级",
    dataIndex: "grade",
    align: "center",
    width: "10%",
    scopedSlots: { customRender: "grade" },
  },
  {
    title: "日期",
    dataIndex: "create_time",
    align: "center",
    width: "20%",
    scopedSlots: { customRender: "create_time" },
  },
//   {
//     title: "操作",
//     key: "operation",
//     align: "center",
//     width: "10%",
//     scopedSlots: { customRender: "operation" },
//   },
];
// 手机格式验证
    let validatorPhone=(rule,value,callback)=>{
      // 如果为空值，就抛出错误
      if(!value){
        callback(new Error("请输入手机号!"));
      }else{
        // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
        if(!/^1[2-9]\d{9}$/.test(value)){
          callback(new Error("手机号格式不正确!"));
        }else{
          callback();
        }
      }
    };

export default {
  name: "userManagement",
  data() {
    return {
      loading: true,
      columns, //表头
      userData: [], //表数据
      childrenUserVisibel:false,//添加下属用户的模态框

      addForm:{//添加下级用户的数据
        name:'',
        phone:''

      },
      rulesAddForm:{
          name:[{required:true,message:'请输入用户名',trigger:'blur'}],
          phone:[{required:true,message:'请输入手机号',trigger:'blur'},{validator:validatorPhone,trigger:"change"}], 
      }
    };
  },
  methods: {
    // 查询隶属下级用户
    getSubordinate() {
      this.$axios
        .post(this.$apis.userList)
        .then((res) => {
          if (res.data.code == 200) {
            this.userData = res.data.data;
            this.$message.success("查询成功");
            this.loading = false;
          } else {
            this.$$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 添加下级用户
    adduser(item) {
      console.log(item);
      this.childrenUserVisibel=true
    },
    // 关闭模态框
    handleCancel(formName){
        this.childrenUserVisibel=false
        this.addForm={
            name:'',
            phone:''
        }
      
          this.$refs[formName].resetFields()
    },
    // 提交模态框数据
    handleOk(formName){
        this.$refs[formName].validate(vali=>{
            if(vali){
                let paraobj={
                    name:this.addForm.name,
                    phone:this.addForm.phone,
                }
                this.$axios.post(this.$apis.addUser,paraobj).then(res=>{
                    if(res.data.code ==200){
                        this.getSubordinate()
                        this.$message.success('添加成功')
                        this.handleCancel('refaddForm')//关闭模态框
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }else{
                return false
            }
        })
    }

  },
  created() {
    this.getSubordinate();
  },
};
</script>

<style lang="less" scoped>
.userManagement {
  .head {
   padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content{
      padding:0 10px;
      height: calc(100vh - 64px - 10px - 60px );
      overflow: auto;
}
.a-table {
    height: auto;
  }

 /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        // max-height:  calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }

}
</style>
