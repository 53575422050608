<template>
  <div class="school">
    <div class="head">
      <h2>学校列表</h2>
      <div>
        <a-button @click="addshoolvisible = true" type="primary" :ghost="true"
          ><a-icon type="plus" />添加学校</a-button
        >
      </div>
    </div>
    <div class="content">
      <!-- 表格 -->
      <a-spin :spinning="spinning">
        <a-table
          class="a-table"
          :columns="columns"
          :data-source="school_list"
          bordered
          rowKey="id"
          :scroll="{
            x: false,
            y: 'calc(100vh - 64px - 10px - 60px - 60px - 24px - 40px)',
          }"
          :pagination="false"
        >
          <!-- 权限 -->
          <div slot="auth_list" slot-scope="record">
            <!-- <a-tag
              @contextmenu.prevent.stop="onClose(record, item)"
              @click="tagChange(record, item)"
              v-for="item in record.auth_list"
              :key="item.id"
              :color="item.auth == 1 ? 'blue' : '#ff4d4f'"
              style="margin: 3px 2px 0 0; cursor: pointer"
            >
              {{ item.name }}
            </a-tag> -->
            <a-collapse accordion v-if="record.auth_list.length !== 0">
              <a-collapse-panel
                v-for="(item, key, index) in record.auth_list"
                :key="index"
                :header="key"
              >
                <!-- <p>{{ item }}</p> -->
                <div v-for="it in item" :key="it.id" class="collapseList">
                  <h3>{{ it.name }}</h3>
                  <div class="btn">
                    <!-- <a-button class="btn_i">删除</a-button> -->
                    <a-switch
                      checked-children="启用"
                      un-checked-children="停用"
                      :checked="it.auth == 1 ? true : false"
                      @change="tagChange(record, it)"
                    />
                    <a-button
                      size="small"
                      class="btn_i"
                      type="danger"
                      @click="onClose(it, record)"
                      >删除</a-button
                    >
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div>
          <!-- 日期 -->
          <div slot="create_time" slot-scope="record, text">
            <!-- {{ $newdate(record * 1000) }}{{text}} -->
            <span v-if="text.update_time">{{
              $newdate(text.update_time * 1000)
            }}</span>
            <span v-else>{{ $newdate(record * 1000) }}</span>

            <!-- {{record}} -->
          </div>
          <!-- 状态 -->

          <div slot="status" slot-scope="record">
            <div v-if="record.order_id">
              <a-tag color="#ff4d4f" style="margin: 5px"> 工单正在审核 </a-tag>
              <a-tag color="#ff4d4f" v-if="record.status == 0"> 停用 </a-tag>
              <a-tag color="#2db7f5" v-if="record.status == 1"> 启用 </a-tag>
              <a-tag color="orange" v-if="record.status == 2">
                {{ $newdate(record.due_date * 1000) }}
              </a-tag>
              <a-tag color="orange" v-if="record.status == 3"> 删除程序 </a-tag>
            </div>
            <div v-else>
              <a-tag color="#ff4d4f" v-if="record.status == 0"> 停用 </a-tag>
              <a-tag color="#2db7f5" v-if="record.status == 1"> 启用 </a-tag>
              <a-tag color="orange" v-if="record.status == 2">
                {{ $newdate(record.due_date * 1000) }}
              </a-tag>
              <a-tag color="orange" v-if="record.status == 3"> 删除程序 </a-tag>
            </div>
          </div>
          <!-- 操作 -->
          <div slot="operation" slot-scope="record">
            <a-button
              style="margin: 2px"
              size="small"
              @click="editschool(record)"
              >编辑</a-button
            >
            <a-button
              style="margin: 2px"
              size="small"
              @click="addMenuschool(record)"
              >添加权限</a-button
            >

            <a-button style="margin: 2px" size="small" @click="inSchool(record)"
              >进入管理</a-button
            >
            <a-button
              style="margin: 2px"
              size="small"
              :disabled="record.order_id != ''"
              @click="editschoolstatus(record)"
              >编辑状态</a-button
            >

            <a-button
              type="danger"
              style="margin: 2px"
              v-if="record.order_id"
              hidden
              size="small"
              >删除</a-button
            >
            <a-popconfirm
              title="是否确认删除?"
              v-else
              @confirm="() => onDelete(record)"
            >
              <a-button type="danger" style="margin: 2px" size="small"
                >删除</a-button
              >
            </a-popconfirm>
            <a-popconfirm
              title="是否确认发送?"
             
              @confirm="() => onSend(record)"
            >
              <a-button type="primary" ghost style="margin: 2px" size="small"
                >通知</a-button
              >
            </a-popconfirm>
            
            <!-- <a-button style="margin:2px" size="small" @click="delMenuschool(record)">删除</a-button> -->
          </div>
        </a-table>
        <div class="pagination">
          <a-pagination
            v-model="pagingnum"
            :page-size="pagination.pagesize"
            :total="pagination.total"
            @change="pagtabel"
          />
        </div>
      </a-spin>
    </div>
    <div v-if="addshoolvisible">
      <Addshool
        :addshoolvisible="addshoolvisible"
        @handleaddvisible="handlevisible"
      ></Addshool>
    </div>
    <div v-if="editshoolvisible">
      <Editshool
        :editshoolvisible="editshoolvisible"
        :editdata="editdata"
        @handleeditvisible="handlevisible"
      ></Editshool>
    </div>
    <div v-if="editshoolstatusvisible">
      <Editshoolstatus
        :editshoolstatusvisible="editshoolstatusvisible"
        :editdata="editdata"
        @handleeditvisible="handlevisible"
      ></Editshoolstatus>
    </div>
    <div v-if="addshoolMenuvisible">
      <AddschoolMenu
        :groupMenuList="groupMenuList"
        :addshoolMenuvisible="addshoolMenuvisible"
        :menudata="menudata"
        @handlementvisible="handlevisible"
      ></AddschoolMenu>
    </div>
  </div>
</template>

<script>
import Addshool from './addschool.vue'
import Editshool from './editschool.vue'
import Editshoolstatus from './editshoolstatus.vue'
import AddschoolMenu from './addschoolMenu.vue'
const columns = [
  {
    title: '学校id',

    // key: 'id',
    dataIndex: 'id',
    align: 'center',
    width: 100,

    scopedSlots: { customRender: 'id' },
  },
  {
    title: '学校服务器iP',
    dataIndex: 'ip',
    align: 'center',
    width: 100,

    scopedSlots: { customRender: 'name' },
  },
  {
    title: '学校服务器端口',
    dataIndex: 'port',
    align: 'center',
    width: 100,

    scopedSlots: { customRender: 'name' },
  },
  {
    title: '学校机构代码',
    dataIndex: 'code',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '学校名称',
    dataIndex: 'name',
    align: 'center',
    width: 100,

    scopedSlots: { customRender: 'name' },
  },

  {
    title: '权限',
    align: 'center',
    width: 300,

    scopedSlots: { customRender: 'auth_list' },
  },
  {
    title: '状态或到期时间',
    key: 'status',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'status' },
    //    scopedSlots: { customRender: 'status', width: '10%' },
  },
  {
    title: '日期',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'center',
    width: 100,

    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '操作',
    align: 'center',
    width: 100,
    key: 'operation',

    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'School',
  components: {
    Addshool,
    Editshool,
    Editshoolstatus,
    AddschoolMenu,
  },
  data: () => {
    return {
      spinning: true, //加载
      addshoolvisible: false, //添加学校列表加载
      editshoolvisible: false, //编辑学校列表加载
      editshoolstatusvisible: false, //编辑学校状态加载
      addshoolMenuvisible: false, //添加学校权限
      columns, //表头
      school_list: [], //表数据

      editdata: {}, //编辑的当前对象
      menudata: {}, //当前添加权限的对象数据
      pagination: {
        num: 1,
        total: 0,
        pagesize: 0,
      },
      pagingnum: 1, //分页，默认是第一页
      groupMenuList: [],
    }
  },
  methods: {
    // 获取学校列表
    getschoolList(page) {
      console.log(page, 'p')
      let paraobj = {
        page,
      }
      this.$axios
        .post(this.$apis.SchoolList, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.school_list = []

            // this.school_list = res.data.data.data
            // res.data.data.data.forEach((item) => {
            //   item.auth_list = this.handArrObj(item.auth_list)
            // })
            this.school_list = res.data.data.data

            this.spinning = false
            this.pagination = {
              num: parseInt(res.data.data.current_page),
              total: parseInt(res.data.data.total),
              pagesize: res.data.data.per_page,
            }
            console.log(this.school_list, 'this.school_list')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handArrObj(obj) {
      let arr = []
      for (let key in obj) {
        let obj_ = {}
        obj_[key] = obj[key]
        arr.push(obj_)
      }
      return arr
    },

    // 关闭模态框
    handlevisible(bol) {
      console.log(bol, 'bol')
      this.addshoolvisible = false
      this.editshoolvisible = false
      this.editshoolstatusvisible = false
      this.addshoolMenuvisible = false
      if (bol == true) {
        this.getschoolList(this.pagination.num)
      }
    },
    //修改学校列表
    editschool(item) {
      this.editdata = item
      this.editshoolvisible = true
      console.log(item)
    },
    // 删除学校列表
    onDelete(item) {
      let paraobj = {
        id: item.id,
      }
      this.$axios.post(this.$apis.delSchool, paraobj).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getschoolList(this.pagination.num)
        } else {
          this.$message.error(res.data.msg)
        }
      })
      console.log(item, 'item')
    },
    //修改学校状态
    editschoolstatus(item) {
      console.log(item, 'item')
      this.editdata = item
      this.editshoolstatusvisible = true
    },

    // 添加学校权限
    addMenuschool(item) {
      this.addshoolMenuvisible = true
      this.menudata = item
    },
    //删除权限
    onClose(i, record) {
      let than = this
      this.$modal.confirm({
        title: '是否确认删除',
        content: '请谨慎选择',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          console.log('OK')
          let paraobj = {
            scid: record.id,
            mid: i.mid,
          }
          than.$axios
            .post(than.$apis.delMenuSchool, paraobj)
            .then((res) => {
              if (res.data.code == 200) {
                than.$message.success('删除成功')
                than.getschoolList(than.pagination.num)
              } else {
                than.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },

    // 切换权限的状态
    tagChange(record, item) {
      let paraobj = {
        scid: record.id,
        mid: item.mid,
        auth: item.auth == 1 ? 0 : 1,
      }
      this.$axios
        .post(this.$apis.menuAuthSchool, paraobj)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success('成功')
            this.getschoolList(this.pagination.num)
          } else {
            this.$message.error('失败')
          }
        })
        .catch((error) => {
          console.log(error)
        })
      console.log(record, 'item', item)
    },
    // 分页
    pagtabel(i) {
      console.log(i, '分页')
      if (i == this.pagination.num) {
        return
      }
      this.getschoolList(i)
    },
    getgroupMenuList() {
      this.$axios.post(this.$apis.groupMenuList).then((res) => {
        if (res.data.code == 200) {
          let arr = []
          let num = 0
          for (let key in res.data.data) {
            num++
            let obj = {}
            obj.name = key
            obj.son = res.data.data[key]
            obj.id = `父id+${num}`
            arr.push(obj)
          }
          this.groupMenuList = arr
          console.log(arr, 'arr')
        } else {
          this.$message.error('失败')
        }
      })
    },
    // 进入管理
    inSchool(record) {
      let token = this.$md5(record.id)
      // 按学校划分
       window.open(
          `http://${record.ip}:${record.port}/login?token=${token}&type=2&key=/Admin/pageTable`
        )
      return
      // 
      let id = record.id
      this.$axios.post(this.$apis.Url_log).then((res) => {
        let token = this.$md5(id)

        window.open(
          `${res.data.url_local_0}login?token=${token}&type=2&key=/Admin/pageTable`
        )
        // `${res.data.url_local_5009}user/login?token=${token}?key=${item.key}`
      })
    },
    // 发送短信
    onSend(record){
      console.log(record,'record');
      let params={
        scid:record.id
      }
      this.$axios.post(this.$apis.sendText,params).then(res=>{
        if(res.data.code ==200){
          this.$message.success(res.data.msg)
        }else{
          this.$message.error(res.data.msg)
        }
      })
    }
  },
  created() {
    this.getschoolList(this.pagination.num)
    this.getgroupMenuList()
  },
}
</script>

<style lang="less" scoped>
.school {
  .head {
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 64px - 10px - 60px);
    overflow: auto;
  }
  .a-table {
    height: auto;
  }

  /deep/.ant-table-content {
    .ant-table-scroll {
      .ant-table-body {
        // max-height:  calc(100vh - 64px - 10px - 60px - 60px - 24px);
        overflow-y: scroll;
      }
    }
  }
  //   分页
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    height: 45px;
  }
}
.collapseList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    .btn_i {
      margin: 0 2px;
    }
  }
}
</style>
